import BN from "bignumber.js"

import { AdTypesEnum, IFiat, IRegion } from "features/p2p/createOrder/types"
import { CRYPTO_DP, CRYPTO_ROUND, ERROR_TYPES, FIAT_DP, FIAT_ROUND } from "./constants"

interface ILimitsAndPaymentTypeState {
  adType: AdTypesEnum
  chosenRegion?: IRegion
  chosenFiat?: IFiat
  maxLimit: BN.Value
  minLimit: BN.Value
  cryptoSum: BN.Value
  activePrice: BN.Value
  walletBalance: BN.Value
  paymentMethodsList: unknown[]
  buyPaymentMethodsList: unknown[]
}

export const validateLimitsAndPaymentType = (values: ILimitsAndPaymentTypeState) => {
  const { adType, chosenRegion, chosenFiat, paymentMethodsList, buyPaymentMethodsList } = values

  const { maxLimit, minLimit, cryptoSum, cryptoBalance, activePrice } = {
    maxLimit: BN(values.maxLimit).dp(FIAT_DP, FIAT_ROUND).toNumber(),
    minLimit: BN(values.minLimit).dp(FIAT_DP, FIAT_ROUND).toNumber(),
    cryptoSum: BN(values.cryptoSum).dp(CRYPTO_DP, CRYPTO_ROUND).toNumber(),
    cryptoBalance: BN(values.walletBalance).dp(CRYPTO_DP, CRYPTO_ROUND).toNumber(),
    activePrice: BN(values.activePrice).dp(FIAT_DP, FIAT_ROUND).toNumber()
  }

  const errors = new Set<string>()

  if (!chosenRegion) errors.add(ERROR_TYPES.CHOOSE_REGION)
  if (!maxLimit) errors.add(ERROR_TYPES.WRONG_MAX_LIMIT)
  if (!minLimit) errors.add(ERROR_TYPES.WRONG_MIN_LIMIT)

  if (maxLimit < minLimit) errors.add(ERROR_TYPES.WRONG_LIMITS)

  if (!cryptoSum) errors.add(ERROR_TYPES.WRONG_TOTAL_SUM)
  if (!chosenFiat) errors.add(ERROR_TYPES.WRONG_FIAT)

  if (chosenFiat && minLimit) {
      const fiatBasedMinLimit = BN(chosenFiat.dollar_exchange)
          .times(5)
          .dp(FIAT_DP, FIAT_ROUND)
          .toNumber()
      if (minLimit < fiatBasedMinLimit) errors.add(ERROR_TYPES.MIN_LIMIT_LT_5)
  }

  if (maxLimit && activePrice && cryptoSum) {
      const fiatBasedMaxLimit = BN(activePrice)
          .times(cryptoSum)
          .dp(FIAT_DP, FIAT_ROUND)
          .toNumber()

      if (maxLimit > fiatBasedMaxLimit) errors.add(ERROR_TYPES.MAX_LIMIT_GT_TOTAL)
  }

  if (cryptoSum <= 0) {
      errors.add(ERROR_TYPES.WRONG_TOTAL_SUM)
  }

  if (adType === AdTypesEnum.SELL && cryptoBalance < cryptoSum) {
      errors.add(ERROR_TYPES.NOT_ENOUGH_BALANCE)
  }

  if (
      (adType === AdTypesEnum.SELL) && 
      (paymentMethodsList.length > 5 || paymentMethodsList.length <= 0) ||
      (adType === AdTypesEnum.BUY) && 
      (buyPaymentMethodsList.length > 5 || buyPaymentMethodsList.length <= 0)
  ) {
      errors.add(ERROR_TYPES.WRONG_PAYMENT_METHODS_NUMBER)
  }

  const errorsKeys = Array.from(errors.keys())

  return !errorsKeys.length
}