import { FC, memo, useCallback, useEffect, useRef, useState } from "react"
import { Slider } from "@pankod/refine-antd"
import styled from "styled-components"
import BN from "bignumber.js"

import { TDealStore } from './hooks/useDealCreateState'
import { useShallowState } from './hooks/useShallowState'

interface IProps {
  useDealCreateStore: TDealStore
  isFiatFlow: boolean
  isDisabled?: boolean
}

const MIN_STEP = 0
const MAX_STEP = 100
const TIMEOUT = 50

export const DealQuantitySlider: FC<IProps> = memo(function DealQuantitySlider({
  useDealCreateStore,
  isFiatFlow,
  isDisabled
}) {
  const [step, setStep] = useState(0)
  const stepValueRef = useRef<BN>()
  const changeTimeoutRef = useRef<number>()

  const {
    rangeMin, rangeMax, currentValue, changeValue
  } = useDealCreateStore(useShallowState(state => {
    return isFiatFlow ? {
      rangeMin: state.minFiat,
      rangeMax: state.maxFiat,
      currentValue: state.fiatSum,
      changeValue: state.actions.setFiatSum
    } : {
      rangeMin: state.minCrypto,
      rangeMax: state.maxCrypto,
      currentValue: state.cryptoSum,
      changeValue: state.actions.setCryptoSum
    }
  }))

  useEffect(() => {
    stepValueRef.current = rangeMax.minus(rangeMin).div(MAX_STEP)
  }, [rangeMin, rangeMax])

  const onChange = useCallback((newStep: number) => {
    setStep(newStep)

    if (changeTimeoutRef.current) {
      window.clearTimeout(changeTimeoutRef.current)
    }

    changeTimeoutRef.current = window.setTimeout(() => {
      const nextValue = rangeMin.plus(
        stepValueRef.current?.times(newStep) ?? 0
      ).toNumber()

      changeValue(nextValue)

      changeTimeoutRef.current = undefined
    }, TIMEOUT)
  }, [rangeMin, changeValue])

  // Just in case - 
  useEffect(() => () => {
    if (changeTimeoutRef.current) window.clearTimeout(changeTimeoutRef.current)
  }, [])

  useEffect(() => {
    const stepSize = stepValueRef.current
    const nextStep = stepSize
      ? currentValue.minus(rangeMin).div(stepSize).dp(0).toNumber()
      : MIN_STEP
    
    if (nextStep <= MIN_STEP) {
      setStep(MIN_STEP)
    } else if (nextStep >= MAX_STEP) {
      setStep(MAX_STEP)
    } else {
      setStep(nextStep)
    }
  }, [rangeMin, rangeMax, currentValue])

  return (
    <CustomSlider
      value={step}
      tooltip={{ open: false }}
      onChange={onChange}
      min={MIN_STEP}
      max={MAX_STEP}
      disabled={isDisabled}
    />
  )
})

const CustomSlider = styled(Slider)`
  margin-top: 12px;
  margin-bottom: 8px;
  max-width: 100%;

  & .ant-slider-handle::after {
    background-color: #703df2 !important;
    border-radius: 4px;
    inset-block-start: -3px !important;
  }

  & .ant-slider-track {
    background-color: #703df2 !important;
  }
`