import React, {useEffect, useState} from 'react';
import {Layout as AntdLayout, Typography} from 'antd';
import type {RefineLayoutHeaderProps} from '@pankod/refine-ui-types';
import {
    NavLink,
    useLocation,
    useNavigate,
} from '@pankod/refine-react-router-v6';
import {SideBarLogo} from '../../icons';
import Icon from '@ant-design/icons';
import LanguageSelect from './language-select';
import NormalDropdown from '../../ui/dropdown/normalDropdown';
import DropdownRowWithHoverIcon from '../../ui/dropdown/dropdownRowWithHoverIcon';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import fb from '../../../../packages/fb/fb';
import {useGetScreenSize} from '../../../hooks/screen/use-get-screen-size';
import {
    DropdownIconSimpleColor,
    Logo,
} from '../../../../pages/paymentLogout/icon';
import {DrawerHeaderContent} from './drawer-content';
import {SmallLogo} from '../../ui/assets/svg/layoutIcons';
import UserComponent from './user-component';
import {Icons} from '@pankod/refine-antd';
import Drawer from '../../ui/Drawer';
import {DEFAULT_PAIR_WITH_DASH} from '../../../../packages/keycloak-client/constants';
import {useAuthState} from '../../../../useAuthState';
import {useCookiesCustom} from '../../../hooks/useCookieCustom';

const {MenuOutlined} = Icons;

const LOGO_SHOW_PATHS = [
    'cryptocurrency-exchange',
    'main',
    'spot/dashboard',
    // 'exchange-outputOrder',
    'terms',
    'policy',
    'blocked',
];

export const Header: React.FC<RefineLayoutHeaderProps> = () => {
    const {cookie} = useCookiesCustom();
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const userAPI = useAuthState((state) => state.userAPI);
    const [activePair, setActivePair] = useState('ETH-BTC');

    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [isLoginScreen, setIsLoginScreen] = useState(false);

    const {width} = useGetScreenSize();
    const isMobileAdaptive = width <= 768;

    const TradingItems = [
        DropdownRowWithHoverIcon({
            key: 'p2pTrading',
            link: '/p2p/dashboard',
            isOutside: false,
            text: `${t('header.menu.trading.p2pTrading')}`,
            iconComp: () => <></>,
        }),
        DropdownRowWithHoverIcon({
            key: 'spotTrading',
            link: `/spot/dashboard/${activePair}`,
            isOutside: false,
            text: `${t('header.menu.trading.spotTrading')}`,
            iconComp: () => <></>,
        }),
        // DropdownRowWithHoverIcon({
        //   key: 'arbitrageTrading',
        //   link: '',
        //   isOutside: false,
        //   text: `${t('header.menu.trading.arbitrageTrading')}`,
        //   iconComp: () => <></>,
        // }),
    ];

    useEffect(() => {
        if (['/', '/login', '/main'].includes(location.pathname)) {
            // navigate(`/spot/dashboard/${DEFAULT_PAIR_WITH_DASH}`);
          navigate('/p2p/dashboard')
        }
        const changeActivePairListener = fb.addListener(
            'changeActivePair',
            (pair: string) => {
                setActivePair(pair);
            }
        );
        return () => {
            changeActivePairListener.remove();
        };
    }, [location.pathname]);

    useEffect(() => {
        if (userAPI?.status === 'banned') {
            navigate('/blocked');
        }
    }, [location.pathname, userAPI?.status]);

    return (
        <HeaderWrapper
            $mode={!window.location.pathname.includes('payment-logout')}
            isMobile={isMobileAdaptive}
            translate="no"
        >
            <Drawer
                width={'100%'}
                open={openMobileMenu}
                onClose={() => {
                    setOpenMobileMenu(false);
                    setIsLoginScreen(false);
                }}
                title={
                    <>
                        <div
                            style={{width: '100%', height: 32}}
                            className={'flex-row align-items-center'}
                        >
                            <NavLink to="/main">
                                <Icon component={() => Logo(undefined, 100, 24)}/>
                            </NavLink>
                        </div>
                    </>
                }
            >
                <DrawerHeaderContent
                    pairWithDash={() => activePair}
                    closeAction={() => setOpenMobileMenu(false)}
                    setIsLoginScreen={setIsLoginScreen}
                    isLoginScreen={() => isLoginScreen}
                />
            </Drawer>
            <HeaderContent isMobile={isMobileAdaptive}>
                <HeaderLinks isMobile={isMobileAdaptive}>
                    {!window.location.pathname.includes('payment-logout') ? (
                        <>
                            {(!userAPI?.email?.length ||
                                LOGO_SHOW_PATHS.some((item) =>
                                    window.location.pathname.includes(item)
                                )) && (
                                <NavLink
                                    to={userAPI?.status !== 'banned' ? '/main' : '/blocked'}
                                >
                                    {!isMobileAdaptive ? (
                                        <Icon component={SideBarLogo} style={{width: 132}}/>
                                    ) : (
                                        <Icon
                                            component={() => SmallLogo({size: 24})}
                                            style={{padding: 12}}
                                        />
                                    )}
                                </NavLink>
                            )}

                            {!isMobileAdaptive && userAPI?.status !== 'banned' && (
                                <NavLink to={'/cryptocurrency-exchange'} style={{padding: 0}}>
                                    <Typography.Text>
                                        {t('header.menu.cryptocurrencyExchange')}
                                    </Typography.Text>
                                </NavLink>
                            )}
                            {/*TODO: Обзор рынка*/}
                            {/*<NavLink to={'/market-overview'}>*/}
                            {/*  <Typography.Text>*/}
                            {/*    {t('header.menu.marketOverview')}*/}
                            {/*  </Typography.Text>*/}
                            {/*</NavLink>*/}
                            {/*TODO: РЫНКИ*/}
                            {/*<NormalDropdown*/}
                            {/*  items={[]}*/}
                            {/*  content={*/}
                            {/*    <div className={'flex-row gap-10 cursor-pointer'}>*/}
                            {/*      <Typography.Text>*/}
                            {/*        {t('header.menu.markets')}*/}
                            {/*      </Typography.Text>*/}
                            {/*      <Icon component={() => DropdownIconSimpleColor()} />*/}
                            {/*    </div>*/}
                            {/*  }*/}
                            {/*/>*/}
                            {!isMobileAdaptive && userAPI?.status !== 'banned' && (
                                <NormalDropdown
                                    itemWidth={236}
                                    items={TradingItems}
                                    content={
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: 10,
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography.Text>
                                                {t('header.menu.trading.trading')}
                                            </Typography.Text>
                                            <Icon component={() => DropdownIconSimpleColor()}/>
                                        </div>
                                    }
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <NavLink to={'/cryptocurrency-exchange'}>
                                <Typography.Text style={{textTransform: 'uppercase'}}>
                                    {t('header.menu.exchange')}
                                </Typography.Text>
                            </NavLink>
                        </>
                    )}
                </HeaderLinks>
                {!isMobileAdaptive && (
                    <HeaderButtons>
                        <UserComponent isAuth={!!cookie['token']}/>
                        <LanguageSelect/>
                    </HeaderButtons>
                )}
                {isMobileAdaptive && (
                    <>
                        <MenuOutlined
                            style={{fontSize: 32, color: '#FBFBFF', padding: 8}}
                            onClick={() => setOpenMobileMenu(true)}
                        />
                    </>
                )}
            </HeaderContent>
        </HeaderWrapper>
    );
};

interface IIsMobile {
    isMobile?: boolean;
}

const HeaderWrapper = styled(AntdLayout.Header)<{ $mode: boolean } & IIsMobile>`
  position: relative;
  z-index: 100;
  height: ${({isMobile}) => (!isMobile ? '80px' : 'none')};
  padding: ${({isMobile}) => (isMobile ? '8px 8px 0 8px' : '')};
  margin-bottom: ${({isMobile}) => (isMobile ? '-16px' : '')};
  background-color: #14141f;
  border-bottom: ${({isMobile}) => (!isMobile ? ' 1px solid' : 'none')};
  line-height: 0;
  ${(props) =>
    props.$mode
        ? css`
          border-color: rgba(65, 61, 80, 0.5);
        `
        : css`
          border-color: rgba(0, 0, 0, 0);
        `};
`;

const HeaderContent = styled.div<IIsMobile>`
  height: ${({isMobile}) => (!isMobile ? '64px' : 'none')};
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const HeaderLinks = styled.div<IIsMobile>`
  height: ${({isMobile}) => (!isMobile ? '32px' : 'none')};
  display: flex;
  gap: 56px;
  cursor: pointer;
  padding: 0;
  align-items: center;
`;

const HeaderButtons = styled.div`
  height: 32px;
  display: flex;
  gap: 32px;
  align-items: center;
`;
