import React, {useEffect, useState} from 'react';
import {notification, Tabs, Typography} from 'antd';
import Button from '../../../shared/components/ui/button';
import './index.css';
import Want, {IWantData} from './want';
import {CrudFilter, HttpError, useList, useNavigation,} from '@pankod/refine-core';
import {IBankTemp, ICurrencyTemp, IFiatTemp, IGetListAdData, IPaymentsType, IRegionTemp,} from './interface';
import {useUserNickname} from 'shared/hooks/use-user-nickname';
import Modal from 'shared/components/ui/modal';
import CustomCollapse from './collapse';
import * as _ from 'lodash';
import VisibilityObserver from '../../../shared/hooks/on-visible/observer';
import {useNavigate} from '@pankod/refine-react-router-v6';
import EmptyComponent from '../../../shared/components/ui/emptyComponent/EmptyComponent';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {ErrorBoundary} from '../../../app/ErrorBoundary';
import {useCookiesCustom} from '../../../shared/hooks/useCookieCustom';

export interface IFilterAd {
    region_id?: CrudFilter;
    fiat?: CrudFilter;
    type?: CrudFilter;
    ccy?: CrudFilter;
    summ?: CrudFilter;
    payment_info?: CrudFilter;
    buy_payment_info?: CrudFilter;
    status: CrudFilter;
    under_moratorium?: CrudFilter;
}

const DashBoard = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isOpenNicknameModal, setIsOpenNicknameModal] = useState(false);
    const [fromCard, setFromCard] = useState(false);
    const [validationError, setValidationError] = useState<string>();
    const [page, setPage] = useState(1);
    const [ads, setAds] = useState<IGetListAdData[]>([]);
    const {removeCookie} = useCookiesCustom();
    const {push: pushRoute} = useNavigation();

    const [initFilter, setInitFilter] = useState<IFilterAd>({
      fiat: {
        field: 'fiat',
        operator: 'eq',
        value: 'USD',
      },
      type: {
        field: 'type',
        operator: 'eq',
        value: 'sell',
      },
      ccy: {
        field: 'ccy',
        operator: 'eq',
        value: 'USDT',
      },
      status: {
        field: 'status',
        operator: 'eq',
        value: 'created',
      },
    });

    const [filter, setFilter] = useState<IFilterAd>(initFilter);

    const onSuccessUpdateCallback = () => {
        if (!fromCard) {
            pushRoute('/p2p/create-offer');
        }
        setIsOpenNicknameModal(false);
        setFromCard(() => false);
    };

    const onServerValidationError = (errorMessage: string) => {
        setValidationError(errorMessage);
    };

    const {
        isLoading: isLoadingAccount,
        userData,
        submitUserNickname,
    } = useUserNickname({
        onSuccessUpdateCallback,
        setValidationError: onServerValidationError,
    });

    const currencyData = useList<ICurrencyTemp, HttpError>({
        dataProviderName: 'config',
        resource: 'currency-constraint',
        config: {
            pagination: {
                current: 1,
                pageSize: 25,
            },
            filters: [
                {
                    field: 'is_available_p2p',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
        queryOptions: {
            retry: false,
            onSuccess: ({data}) => {
                const tmpFilterData: IFilterAd = {
                    ...filter,
                    ccy: {
                        field: 'ccy',
                        operator: 'eq',
                        value: data[0]?.currency,
                    },
                };
                setInitFilter((prevState) => ({
                    ...prevState,
                    ccy: {
                        field: 'ccy',
                        operator: 'eq',
                        value: data[0]?.currency,
                    },
                }));
                setFilter((prevState) => ({
                    ...prevState,
                    ccy: {
                        field: 'ccy',
                        operator: 'eq',
                        value: data[0]?.currency,
                    },
                }));
            },
        },
    });

    const fiatData = useList<IFiatTemp, HttpError>({
        dataProviderName: 'config',
        resource: 'fiat',
        config: {
            pagination: {
                current: 1,
                pageSize: 25,
            },
            filters: [
                {
                    field: 'is_available_p2p',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
        queryOptions: {
            retry: false,
            onSuccess: ({data}) => {
                setInitFilter((prevState) => ({
                    ...prevState,
                    fiat: {
                        field: 'fiat',
                        operator: 'eq',
                        value: data[0]?.currency,
                    },
                }));
                setFilter((prevState) => ({
                    ...prevState,
                    fiat: {
                        field: 'fiat',
                        operator: 'eq',
                        value: data[0]?.currency,
                    },
                }));
            },
        },
    });

    const regionsData = useList<IRegionTemp, HttpError>({
        dataProviderName: 'p2p',
        resource: 'regions',
        config: {
            pagination: {
                current: 1,
                pageSize: 50,
            },
        },
        queryOptions: {
            retry: false,
        },
    });

    const banksData = useList<IBankTemp, HttpError>({
        dataProviderName: 'p2p',
        resource: 'banks',
        config: {
            pagination: {
                current: 1,
                pageSize: 25,
            },
            sort: [
                {
                    field: 'name',
                    order: 'asc',
                },
            ],
        },
        queryOptions: {
            retry: false,
        },
    });
    const data = useList<IGetListAdData, HttpError>({
        dataProviderName: `p2p`,
        resource: `offer`,
        config: {
            pagination: {
                current: page,
            },
            filters: Object.values(
                _.omit(
                    filter,
                    filter.type?.value === 'buy' ? 'payment_info' : 'buy_payment_info'
                )
            ),
        },
        queryOptions: {
            onSuccess: (res) => {
                if (page === 1) {
                    setAds([...res.data]);
                } else setAds([...ads, ...res.data]);
            },
            retry: false,
        },
    });

    const iconsData = useList<IPaymentsType, HttpError>({
        dataProviderName: `p2p`,
        resource: `payment-types`,
    });

    const onHandleChangeType = (value: string) => {
        setAds([]);
        setFilter({
            ...filter,
            type: {
                field: 'type',
                operator: 'eq',
                value,
            },
        });
    };

    useEffect(() => {
        setPage(1);
    }, [filter]);

    const handleNextPage = (visibility: boolean) => {
        if (visibility && data.data?.total !== ads.length) {
            setPage((prevState) => prevState + 1);
        }
    };

    const wantData: IWantData = {
        data,
        filter,
        regions: regionsData.data?.data,
        initFilter,
        setFilter,
        fiats: fiatData.data?.data,
        banks: banksData.data?.data,
        currency: currencyData.data?.data,
        iconsData: iconsData.data?.data,
        setAds,
        isLoading: data.isLoading,
        refetchOffers: data.refetch,
    };

    const createHandleClick = () => {
        if (!!userData) {
            if (!userData.nickname?.length) {
                setIsOpenNicknameModal(true);
            } else if (!!userData.nickname?.length) navigate('/p2p/create-offer');
        } else {
            notification.info({
                message: t('messages.info.pleaseLogin'),
            });
        }
    };

    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}>
            <TypographyTitle>{t('dashboard.title')}</TypographyTitle>
            <TabsWrapper>
                <Tabs
                    activeKey={
                        !!filter.type?.value ? filter.type?.value : initFilter.type?.value
                    }
                    onChange={onHandleChangeType}
                    items={[
                        {
                            key: 'sell',
                            label: t('dashboard.tabs.buy'),
                        },
                        {
                            key: 'buy',
                            label: t('dashboard.tabs.sell'),
                        },
                    ]}
                />

                <ButtonWrapper>
                    <ButtonStyled
                        width={'180px'}
                        height={'40px'}
                        onClick={createHandleClick}
                    >
                        {t('dashboard.buttons.create')}
                    </ButtonStyled>
                </ButtonWrapper>

                {userData ? (
                    <Modal.NicknameModal
                        validationError={validationError}
                        isLoading={isLoadingAccount}
                        userData={userData}
                        onSubmit={submitUserNickname}
                        onCancel={() => setIsOpenNicknameModal(false)}
                        open={isOpenNicknameModal}
                        onNicknameChange={() => setValidationError(undefined)}
                    />
                ) : null}
            </TabsWrapper>
            <Want {...wantData} />
            <CollapseWrapper>
                {!!ads?.length &&
                    ads.map((item, index) =>
                        index === ads.length - 1 ? (
                            <VisibilityObserver
                                onChangeVisibility={handleNextPage}
                                options={{rootMargin: '100px'}}
                                key={`dashboard-collapse-${item.id}`}
                            >
                                <CustomCollapse
                                    key={`dashboard-collapse-${item.id}`}
                                    type={filter.type?.value}
                                    advData={item}
                                    user={userData}
                                    setOpenModal={setIsOpenNicknameModal}
                                    setFromCard={setFromCard}
                                    iconsData={iconsData.data?.data}
                                    regions={regionsData.data?.data}
                                />
                            </VisibilityObserver>
                        ) : (
                            <CustomCollapse
                                type={filter.type?.value}
                                advData={item}
                                key={`dashboard-collapse-${item.id}`}
                                user={userData}
                                setOpenModal={setIsOpenNicknameModal}
                                setFromCard={setFromCard}
                                iconsData={iconsData.data?.data}
                                regions={regionsData.data?.data}
                            />
                        )
                    )}
                {!ads?.length && (
                    <EmptyComponent text={t('emptyComponent.noAdFound')}/>
                )}
            </CollapseWrapper>
        </ErrorBoundary>
    );
};

export default DashBoard;

const TypographyTitle = styled(Typography.Title)`
    color: #fbfbff;
`;

const TabsWrapper = styled.div`
    position: relative;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const ButtonStyled = styled(Button)`
    background: linear-gradient(270.4deg, #6e27f8 -85.22%, #20d9c0 164.99%);
`;

const CollapseWrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    gap: 16px;
    flex-direction: column;
`;
