import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Form, Row, Tag, Typography } from '@pankod/refine-antd'
import {
  HttpError,
  useCreate,
  useList,
  useOne,
  useTranslate,
} from '@pankod/refine-core'
import { useNavigate } from '@pankod/refine-react-router-v6'
import BN from 'bignumber.js'
import { uniqBy } from 'lodash'
import { styled } from 'styled-components'

import { useAuthState } from 'useAuthState'
import Button from 'shared/components/ui/button'
import { ButtonsTypes } from 'shared/components/ui/button/filledButton.p'
import { ProfileCircle } from 'shared/components/icons'
import useDebounce from 'shared/hooks/use-debounce'
import { useCookiesCustom } from 'shared/hooks/useCookieCustom'
import { IGetListAdData, IPaymentInfo } from './interface'
import { MethodsPayment, MethodsPaymentBuy } from './methodsPayment'
import { AdExpanded } from '../lowResAdExpanded'
import { USDT } from '../lib/constants'

import './index.css'

enum OfferType {
  BUY = 'buy',
  SELL = 'sell',
}

const CustomCollapse: FC<{
  type: OfferType
  item: IGetListAdData
}> = ({ type = OfferType.BUY, item }) => {
  const { cookie } = useCookiesCustom()
  const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid']
  const navigate = useNavigate()
  const login = useAuthState((state) => state.login)
  const [isOpen, setIsOpen] = useState(false)

  const translate = useTranslate()
  const [isUpdate, setIsUpdate] = useState(false)
  const [dataAd, setDataAd] = useState<IGetListAdData>(item)
  const [paymentData, setPaymentData] = useState<IPaymentInfo[]>()
  const [error, setError] = useState<{
    buy?: string
    payment?: string
  }>({})
  const [form] = Form.useForm()
  const buyWatch = Form.useWatch(OfferType.BUY, form)
  const sellWatch = Form.useWatch(OfferType.SELL, form)
  const paymentMethodWatch = Form.useWatch('paymentMethod', form)
  const debouncedBuy = useDebounce(buyWatch, 500)
  const debouncedSell = useDebounce(sellWatch, 500)
  const { mutate } = useCreate<{ data: { id: string } }>({
    mutationOptions: { retry: false },
  })

  const { refetch } = useOne<IGetListAdData, HttpError>({
    dataProviderName: 'p2p',
    resource: 'offer',
    id: dataAd.id,
    queryOptions: {
      onSuccess: (res) => setDataAd(res.data),
      enabled: false,
      retry: false,
    },
  })

  const { refetch: paymentRefetch } = useList<IPaymentInfo, HttpError>({
    dataProviderName: 'p2p',
    resource: 'payment',
    config: {
      filters: [
        {
          field: 'account_id',
          operator: 'eq',
          value: uid,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 100,
      },
    },
    queryOptions: {
      enabled: false,
      onSuccess: (res) => setPaymentData(res.data),
      retry: false,
    },
  })

  const handleOpen = async () => {
    setIsOpen(true)
    if (!isOpen && type === OfferType.BUY) {
      await paymentRefetch()
    }
  }

  const handleCloseCard = async () => {
    setIsOpen(false)
    if (!isOpen && type === OfferType.BUY) {
      await paymentRefetch()
    }
  }

  const handleChangeBuy = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.-]/g, '')
    const numValue = BN(value)
    const type = e.target.ariaLabel

    if (type === OfferType.BUY) {
      const buyValue =
        numValue.comparedTo(dataAd.max_sum_limit) === -1
          ? value
          : dataAd.max_sum_limit
      const sellValue = BN(buyValue).div(dataAd.price).toString()

      form.setFieldValue(OfferType.BUY, buyValue)
      form.setFieldValue(OfferType.SELL, buyValue === '' ? buyValue : sellValue)
    } else if (type === OfferType.SELL) {
      const sellValue =
        numValue.comparedTo(typeText.sellMax) === -1
          ? value
          : typeText.sellMax.toString()
      const buyValue = BN(sellValue).times(dataAd.price).toString()

      form.setFieldValue(OfferType.SELL, sellValue)
      form.setFieldValue(OfferType.BUY, sellValue === '' ? sellValue : buyValue)
    }
  }

  const handleUpdate = async () => {
    if (cookie['token']) {
      await refetch()
      setIsUpdate(false)
    } else {
      login()
    }
  }

  const handleCreate = async () => {
    if (
      !(error.buy && +buyWatch < +dataAd.min_sum_limit) && cookie['token']
    )
      mutate(
        {
          dataProviderName: 'p2p',
          resource: 'order',
          values: {
            ad_id: dataAd.id,
            sum: sellWatch ? +sellWatch : undefined,
            payment_id: paymentMethodWatch,
          },
        },
        {
          onSuccess: (res) => {
            navigate(`/p2p/order/${res.data.data.id}`)
          },
          onError: (res) => {
            setError({
              buy: res?.response?.data?.errors?.sum?.join(' ')
                ? res?.response?.data?.errors?.sum?.join(' ')
                : undefined,
              payment: res?.response?.data?.errors?.payment_id?.join(' ')
                ? res?.response?.data?.errors?.payment_id?.join(' ')
                : undefined,
            })
          },
        },
      )
    else {
      login()
    }
  }

  const typeText = useMemo(() => {
    return {
      text:
        isUpdate && isOpen
          ? translate('dashboard.collapseBody.update')
          : translate(`dashboard.collapseHeader.button.${type}`),
      input: [
        translate(`dashboard.collapseBody.${type}.first`),
        translate(`dashboard.collapseBody.${type}.last`),
      ],
      placeholder: `${(+dataAd.min_sum_limit / +dataAd.price)?.toFixed(
        item.ccy === USDT ? 2 : 8,
      )} - ${(+dataAd.max_sum_limit / +dataAd.price)?.toFixed(8)}`,
      sellMin: +(+dataAd.min_sum_limit / +dataAd.price)?.toFixed(
        item.ccy === USDT ? 2 : 8,
      ),
      sellMax: +(+dataAd.max_sum_limit / +dataAd.price)?.toFixed(
        item.ccy === USDT ? 2 : 8,
      ),
    }
  }, [
    dataAd.max_sum_limit,
    dataAd.min_sum_limit,
    dataAd.price,
    isOpen,
    isUpdate,
    item.ccy,
    translate,
    type,
  ])

  const countDeals = useMemo(() => {
    return (
      <CountDealsWrapperOne>
        <span>
          {dataAd.owner_info.total_orders_amount}{' '}
          {translate('dashboard.collapseHeader.countDeals')}
        </span>
        <span>
          {(
            ((dataAd.owner_info.success_orders_amount || 0) /
              (dataAd.owner_info.total_orders_amount || 1)) *
            100
          )?.toFixed()}
          % {translate('dashboard.collapseHeader.countCompleted')}
        </span>
      </CountDealsWrapperOne>
    )
  }, [
    dataAd.owner_info.success_orders_amount,
    dataAd.owner_info.total_orders_amount,
    translate,
  ])

  const methodArray = useCallback(
    (icon: boolean = false, method = false) => {
      switch (dataAd.type) {
        case OfferType.BUY:
          if (method && isOpen && paymentData?.length !== undefined) {
            return MethodsPaymentBuy({
              data: uniqBy(
                paymentData?.map((item) => ({
                  ...item.payment_details[0],
                  payment_type: item.payment_type,
                })),
                'id',
              ) as IGetListAdData['buy_payment_info'],
              icon,
            })
          }
          return MethodsPaymentBuy({
            data: dataAd?.buy_payment_info ?? [],
            icon,
          })
        case OfferType.SELL:
          return MethodsPayment({
            data: dataAd?.payment_info ?? [],
            icon,
          })
      }
    },
    [isOpen, paymentData, dataAd],
  )

  const plusCount = useMemo(() => {
    const count =
      dataAd.type === OfferType.BUY
        ? (dataAd?.buy_payment_info?.length ?? 0)
        : (dataAd?.payment_info as IPaymentInfo[])
            .map((item) => item.payment_details)
            .flat().length
    return <>{count - 2 > 0 && <TagStyled>+{count - 2}</TagStyled>}</>
  }, [dataAd])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setIsUpdate(() => true), 15000)
    }
  }, [isUpdate, isOpen])

  useEffect(() => {
    const value =
      buyWatch === '' || buyWatch === undefined
        ? buyWatch
        : parseFloat(buyWatch)?.toFixed(2)
    if (+value < +dataAd.min_sum_limit && value !== '')
      setError({ ...error, buy: 'min' })
    else setError({ ...error, buy: undefined })
    form.setFieldValue(OfferType.BUY, value)
  }, [debouncedBuy])

  useEffect(() => {
    form.setFieldValue(
      OfferType.SELL,
      sellWatch === '' || sellWatch === undefined
        ? sellWatch
        : parseFloat(sellWatch)?.toFixed(item.ccy === USDT ? 2 : 8),
    )
  }, [debouncedSell])

  return (
    <Wrapper onClick={handleOpen}>
      {isOpen ? (
        <>
          <AdExpanded
            isOpen={isOpen}
            typeText={typeText}
            dataAd={dataAd}
            item={item}
            form={form}
            error={error}
            handleChangeBuy={handleChangeBuy}
            buyWatch={buyWatch}
            methodArray={methodArray}
            isUpdate={isUpdate}
            handleUpdate={handleUpdate}
            handleCreate={handleCreate}
            close={handleCloseCard}
          />
          <Labels>
            {methodArray()?.map((item, index) => (
              <Tag
                color={'#474755'}
                key={`dashboard-collapse-open-tab-${index}`}
                style={{ margin: 0 }}
              >
                {item.label}
              </Tag>
            ))}
          </Labels>
        </>
      ) : (
        <RowsWrap>
          <Row>
            <RowWrapper
              $display={'flex'}
              $justifyContent={'space-between'}
              $width={'100%'}
            >
              <CountDealsWrapper>
                <div>
                  <UserProfile>
                    <ProfileCircle />
                    <SpanStyled $color={'#FAFAFC'} $maxWidth={'70%'}>
                      {dataAd.owner_info.nickname}
                    </SpanStyled>
                  </UserProfile>
                </div>
                <div>
                  <p>{countDeals}</p>
                </div>
              </CountDealsWrapper>
              <MethodArrayWrapper>
                {methodArray()?.slice(0, 2)
                  .map((item, index) => (
                    <TagStyled
                      $display={'flex'}
                      $alignItems={'center'}
                      $maxWidth={125}
                      color={'#474755'}
                      key={`dashboard-collapse-tab-${index}`}
                    >
                      <SpanStyled>{item.label}</SpanStyled>
                    </TagStyled>
                  ))}
                {plusCount}
              </MethodArrayWrapper>
            </RowWrapper>
          </Row>
          <Row>
            <RowWrapper $display={'flex'} $flexDirection={'column'}>
              <FlexWrapper>
                <TypographyStyled
                  $color={'#C3C3CE'}
                  $fontSize={12}
                  $minWidth={75}
                >
                  {translate('dashboard.collapseHeader.limit')}{' '}
                </TypographyStyled>
                <TypographyStyled $color={'#FBFBFF'}>
                  {dataAd.min_sum_limit} - {dataAd.max_sum_limit} {dataAd.fiat}
                </TypographyStyled>
              </FlexWrapper>
              <FlexWrapper>
                <TypographyStyled
                  $color={'#C3C3CE'}
                  $fontSize={12}
                  $minWidth={75}
                >
                  {translate('dashboard.collapseHeader.available')}{' '}
                </TypographyStyled>
                <TypographyStyled $color={'#FBFBFF'}>
                  {dataAd.sum_left} {dataAd.ccy}
                </TypographyStyled>
              </FlexWrapper>
            </RowWrapper>
          </Row>
          <Row>
            <RowWrapper
              $display={'flex'}
              $justifyContent={'space-between'}
              $alignItems={'center'}
              $flexDirection={'row'}
              $width={'100%'}
            >
              <Flex>
                <TypographyStyled
                  $color={'#FAFAFC'}
                  $fontSize={18}
                  $fontWeight={700}
                >
                  {dataAd.price} {dataAd.fiat}
                </TypographyStyled>
              </Flex>
              <Flex>
                <Button
                  onClick={() => {}}
                  type={ButtonsTypes.outlined}
                  height={'40px'}
                  width={'120px'}
                >
                  {typeText.text}
                </Button>
              </Flex>
            </RowWrapper>
          </Row>
        </RowsWrap>
      )}
    </Wrapper>
  )
}

export default CustomCollapse

const Labels = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`

const CountDealsWrapperOne = styled.div`
  display: flex;
  gap: 10px;
  color: #c3c3ce;
  font-size: 12px;
`

const TagStyled = styled(Tag)<{
  $display?: string
  $alignItems?: string
  $maxWidth?: number
}>`
  display: ${(props) => props.$display};
  align-items: ${(props) => props.$alignItems};
  max-width: ${(props) => props.$maxWidth}px;
  color: #fbfbff;
  font-weight: 500;
  font-size: 10px;
  height: 20px;
  margin: 0;
`

const Wrapper = styled.div`
  border: 1px solid #413d5080;
  background-color: #1c1c27;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
`

const RowWrapper = styled.div<{
  $display?: string
  $justifyContent?: string
  $alignItems?: string
  $flexDirection?: string
  $width?: string
}>`
  display: ${(props) => props.$display};
  justify-content: ${(props) => props.$justifyContent};
  align-items: ${(props) => props.$alignItems};
  flex-direction: ${(props) => props.$flexDirection};
  width: ${(props) => props.$width};
`

const SpanStyled = styled.span<{
  $color?: string
  $maxWidth?: string
}>`
  color: ${(props) => props.$color};
  max-width: ${(props) => props.$maxWidth};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const MethodArrayWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: row-reverse;
  max-width: 30%;
  gap: 4px;
  flex-wrap: wrap;
  margin: 0;
`

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 190px;
`

const TypographyStyled = styled(Typography)<{
  $color?: string
  $fontSize?: number
  $fontWeight?: number
  $minWidth?: number
}>`
  color: ${(props) => props.$color};
  font-size: ${(props) => props.$fontSize}px;
  font-weight: ${(props) => props.$fontWeight};
  min-width: ${(props) => props.$minWidth}px;
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Flex = styled.div`
  display: flex;
`

const CountDealsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 60%;
`

const RowsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
