import React from 'react'
import { shallow } from 'zustand/shallow'

export function useShallowState<S, U>(selector: (state: S) => U): (state: S) => U {
  const prev = React.useRef<U>()

  return (state) => {
    const next = selector(state)

    if (shallow(prev.current, next)) {
      return prev.current as U
    } 

    return prev.current = next
  }
}