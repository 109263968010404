import { useEffect, useState } from "react"
import BN from "bignumber.js"

import { useWalletState } from "useWalletState"
import { getAmountByCurrency } from "../lib/utils"

export const useBalances = (cryptoCode: string, pricePerCoin: BN) => {
  const balances = useWalletState((state) => state.balances)
  const [balanceInFiat, setBalanceAsFiat] = useState(() => BN(0))
  const [balanceCrypto, setBalanceCrypto] = useState(() => BN(0))
  
  const foundBalance = getAmountByCurrency(balances, cryptoCode)
  useEffect(() => {
    const bnCryptoBalance = BN(foundBalance)

    setBalanceCrypto(bnCryptoBalance)
    setBalanceAsFiat(
      bnCryptoBalance.times(pricePerCoin),
    )
  }, [foundBalance, pricePerCoin])

  return {
    balanceCrypto,
    balanceInFiat
  }
}
