import { useRef } from 'react'
import BN from 'bignumber.js'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'

import { ADV_TYPE, CRYPTO_DP, CRYPTO_ROUNDING, FIAT_DP, FIAT_ROUNDING } from '../lib/constants'
import { IDealCreateState, TDealCreateUpdate } from '../interface'
import { composeDealCreateState } from '../lib/utils'

const INITIAL_STATE: IDealCreateState = (() => {
  const zero = BN(0)

  return {
    minFiat: zero,
    maxFiat: zero,
    minCrypto: zero,
    maxCrypto: zero,
    advType: ADV_TYPE.SELL,
    pricePerCoin: zero,
    fiatSum: zero,
    cryptoSum: zero
  }
})()

const storesMap = new WeakMap<{}, ReturnType<typeof createStore>>()

const createStore = (initialState: Partial<TDealCreateUpdate> = {}) => {
  const state = {
    ...INITIAL_STATE,
    ...composeDealCreateState(initialState, INITIAL_STATE)
  }

  return create(
    combine(state, (set, get) => ({
      actions: {
        update(data: Partial<TDealCreateUpdate>) {
          const currentState = get()
          const newState = composeDealCreateState(data, currentState)
  
          set(newState)
        },
        setFiatSum(value: BN.Value) {
          const { pricePerCoin } = get()
          const bnValue = BN(value)
          const newState: Partial<IDealCreateState> = {
            fiatSum: bnValue
          }
  
          newState.cryptoSum = newState.fiatSum?.div(pricePerCoin)

          set(newState)
        },
        setCryptoSum(value: BN.Value) {
          const { pricePerCoin } = get()
          const bnValue = BN(value)
          const newState: Partial<IDealCreateState> = {
            cryptoSum: bnValue
          }
  
          newState.fiatSum = newState.cryptoSum!.times(pricePerCoin)

          set(newState)
        },
        getOutput() {
          const {
            pricePerCoin, minCrypto, maxCrypto, minFiat, maxFiat, fiatSum, cryptoSum
          } = get()
  
          return {
            pricePerCoin: pricePerCoin.toFixed(FIAT_DP, FIAT_ROUNDING),
            minFiat: minFiat.toFixed(FIAT_DP, FIAT_ROUNDING),
            maxFiat: maxFiat.toFixed(FIAT_DP, FIAT_ROUNDING),
            minCrypto: minCrypto.toFixed(CRYPTO_DP, CRYPTO_ROUNDING),
            maxCrypto: maxCrypto.toFixed(CRYPTO_DP, CRYPTO_ROUNDING),
            fiatSum: fiatSum.toFixed(FIAT_DP, FIAT_ROUNDING),
            cryptoSum: cryptoSum.toFixed(CRYPTO_DP, CRYPTO_ROUNDING),
          }
        }
      }
    }))
  )
}

const getStore = (ref: {}, initialState?: Partial<TDealCreateUpdate>) => {
  if (storesMap.has(ref)) {
    return storesMap.get(ref)!
  }

  const store = createStore(initialState)

  storesMap.set(ref, store)

  return store
}

export type TDealStore = ReturnType<typeof useDealCreateStore>

export const useDealCreateStore = (initialState?: Partial<TDealCreateUpdate>) => {
  const storeRef = useRef({})

  const store = getStore(storeRef, initialState)

  return store
}