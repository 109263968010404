import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import {
  ConfigProvider,
  Form,
  Icon,
  InputNumber,
  Radio,
  Slider,
  Tooltip,
  Typography,
} from '@pankod/refine-antd'
import BN from 'bignumber.js'

import { AdPriceType, AdTypesEnum, IFiat } from 'features/p2p/createOrder/types'
import { ICryptoCurrencyConstraintItem } from 'widgets/order/types'
import { ChevronDown } from 'shared/components/icons'
import { ReactComponent as QuestionOutlinedIcon } from 'shared/components/ui/assets/svg/questionOutlined.svg'
import { AdditionalPriceRange } from './model/types'
import AdPriceSlider from './ui/adPriceSlider'
import AdPriceInput from './ui/adPriceInput'
import { FIAT_DP, FIAT_ROUND } from '../../lib/constants'

const SLIDER_STEP_DP = 4

interface IChooseAdTypeProps {
  adType: AdTypesEnum

  handleChangeAdType(type: AdTypesEnum): void

  fiatList?: Array<IFiat>
  currencyList?: Array<ICryptoCurrencyConstraintItem>
  chosenCurrency?: ICryptoCurrencyConstraintItem
  chosenFiat?: IFiat

  handleChangeCurrency(currency?: ICryptoCurrencyConstraintItem): void

  handleChangeFiat(fiat?: IFiat): void

  isCurrencyListLoading: boolean
  isFiatListLoading: boolean
  activePrice: number

  handleChangeAdCurrencyPrice(value: number): void

  adCurrencyPrice: number
  isNextBtnClick?: boolean
  existingAds?: string[]
}

const ChooseP2PAdTypeCard: FC<IChooseAdTypeProps> = ({
  adCurrencyPrice,
  activePrice,
  handleChangeAdCurrencyPrice,
  chosenCurrency,
  chosenFiat,
  isNextBtnClick,
}) => {
  const { t } = useTranslation()

  const activePriceBN = BN(activePrice).dp(FIAT_DP, FIAT_ROUND)
  const activePriceNumber = activePriceBN.toNumber()

  const [priceRange, setPriceRange] = useState<AdditionalPriceRange>(() => {
    return {
      min: activePriceBN,
      max: activePriceBN,
      step: BN(0),
    }
  })

  useEffect(() => {
    const min = activePriceBN.times(0.8).dp(FIAT_DP, FIAT_ROUND)
    const max = activePriceBN.times(1.3).dp(FIAT_DP, FIAT_ROUND)
    const step = max.minus(min).div(100).dp(SLIDER_STEP_DP)

    setPriceRange({
      min,
      max,
      step,
    })
  }, [activePriceNumber])

  const preconditionsMet = chosenCurrency && chosenFiat && activePriceNumber > 0

  const priceStepChange = (direction: 'up' | 'down' = 'up') => {
    const compute = direction === 'up' ? activePriceBN.plus : activePriceBN.minus
    let nextPrice = compute(priceRange.step.times(10)).dp(FIAT_DP, FIAT_ROUND)

    if (nextPrice.comparedTo(priceRange.min) === -1) {
      nextPrice = priceRange.min
    }

    if (nextPrice.comparedTo(priceRange.max) === 1) {
      nextPrice = priceRange.max
    }

    handleChangeAdCurrencyPrice(nextPrice.toNumber())
  }

  const priceValueString =
    chosenCurrency && chosenFiat
      ? adCurrencyPrice.toFixed(2)
      : '0.00'

  return (
    <div className="choose-p2p-ad-type-card">
      <div className="p2p-ad-card-content">
        <Typography className="create-p2p-ad-card-title">
          {t('p2p.chooseAdType.typeAndSizeOfPrice')}
        </Typography>
        <div className="p2p-ad-card-content">
          <ContainerPriceType className="price-type">
            <Typography>{t('announcements.params.priceType')}</Typography>
            {/*<Radio.Group value={AdPriceType.FIXED}>*/}
            {/*  <Radio style={{color: 'white'}} value={AdPriceType.FIXED}>*/}
            {/*    {t('p2p.chooseAdType.radio.fixType')}*/}
            {/*  </Radio>*/}
            {/*  <Radio*/}
            {/*    style={{color: 'white'}}*/}
            {/*    value={AdPriceType.FLOAT}*/}
            {/*    disabled*/}
            {/*  >*/}
            {/*    {t('p2p.chooseAdType.radio.floatingType')}*/}
            {/*  </Radio>*/}
            {/*</Radio.Group>*/}
            <div className="p2p-price-type">
              {t('p2p.chooseAdType.radio.fixType')}
            </div>
          </ContainerPriceType>
          <div className="price-slider-slider-wrapper">
            <div className="price-slider-slider-wrapper">
              <Typography>
                {t('p2p.chooseAdType.costOfOneUnitInFiat')}
              </Typography>
              <div className="choose-p2p-ad-type-slider-form-wrapper">
                <AdPriceSlider
                  adPriceValue={adCurrencyPrice}
                  priceRange={priceRange}
                  changeAdPriceValue={handleChangeAdCurrencyPrice}
                  isDisabled={!preconditionsMet}
                />
                <Form.Item>
                  <div
                    style={{ position: 'relative' }}
                    className={'input-number-in-create-ad'}
                  >
                    <PriceInputStyled
                      isDisabled={!preconditionsMet}
                      adPriceValue={adCurrencyPrice}
                      changeAdPriceValue={handleChangeAdCurrencyPrice}
                      priceRange={priceRange}
                      postfix={' EUR'}
                    />
                    <ContainerIcons
                      className={
                        'flex justify-content-space-between flex-column input-number-arrows'
                      }
                    >
                      <IconStyled
                        component={() => ChevronDown('#767687', 10)}
                        style={{
                          rotate: '180deg',
                        }}
                        onClick={() => {
                          priceStepChange('up')
                        }}
                      />
                      <IconStyled
                        component={() => ChevronDown('#767687', 10)}
                        onClick={() => {
                          priceStepChange('down')
                        }}
                      />
                    </ContainerIcons>
                  </div>
                </Form.Item>
              </div>
              <div className="choose-p2p-ad-type-price-type-wrapper">
                <div className="item">
                  <Typography.Text className="create-p2p-ad-medium-text">
                    {activePriceNumber ? activePriceNumber.toFixed(2) : '0.00'} {chosenFiat?.currency}
                  </Typography.Text>
                  <Tooltip
                    trigger={'hover'}
                    placement="bottom"
                    title={t('p2p.chooseAdType.recommendPrice')}
                    color={'#2B2B36'}
                    arrow={false}
                    overlayInnerStyle={{
                      color: '#FBFBFF',
                      backgroundColor: '#2B2B36',
                      padding: '6px 16px',
                      maxWidth: 238,
                      border: '1px solid #767687',
                    }}
                  >
                    <div
                      style={{ cursor: 'pointer' }}
                      className="centered-block"
                    >
                      <QuestionOutlinedIcon />

                      <Typography className="choose-p2p-ad-type-helper-text">
                        {t('p2p.chooseAdType.marketPrice')}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                <div className="item">
                  <Typography.Text className="create-p2p-ad-medium-text">
                    {priceValueString} {chosenFiat?.currency}
                  </Typography.Text>
                  <div style={{ display: 'flex', gap: 8 }}>
                    <Typography className="choose-p2p-ad-type-helper-text">
                      {t('p2p.chooseAdType.priceAd')}
                    </Typography>
                  </div>
                </div>
              </div>
              <Typography style={{ fontSize: 12 }}>
                {t('p2p.chooseAdType.description')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="choose-p2p-ad-type-price-bottom-block">
        <Typography
          className="create-p2p-ad-medium-text"
          style={{ textAlign: 'end', width: '100%' }}
        >
          {`1 ${
            chosenCurrency
              ? chosenCurrency.currency
              : t('p2p.chooseAdType.active').toUpperCase()
          } `}
        </Typography>
        <Typography
          className="create-p2p-ad-medium-text"
          style={{ textAlign: 'center' }}
        >
          =
        </Typography>
        <Typography
          className="create-p2p-ad-medium-text"
          style={{ textAlign: 'start', width: '100%' }}
        >
          {` ${
            chosenFiat
              ? `${priceValueString} ${chosenFiat.currency}`
              : t('p2p.chooseAdType.fiat').toUpperCase()
          }`}
        </Typography>
      </div>
    </div>
  )
}

const IconStyled = styled(Icon)`
  user-select: none;
`

const ContainerPriceType = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const PriceInputStyled = styled(AdPriceInput)`
  width: 100%;
  background-color: transparent;
  color: white !important;
  border-radius: 8px;
  height: 32px;
  padding: 5px 11px 7px;
  font-size: 14px;
  line-height: 1;
`

const ContainerIcons = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 5px;
`

export default ChooseP2PAdTypeCard
