import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import './style.css'
import FirstStep from './firstStep'
import Stepper from './stepper'
import Footer from './footer'
import SecondStep from './secondStep'
import {
  Payments,
  PayService,
} from '../../../pages/p2p/settings/payment/payment'
import { IPaymentsType } from '../../../pages/p2p/dashboard/interface'
import { IPaymentType } from 'features/p2p/createOrder/types'
import {
  GetListResponse,
  HttpError,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useCreate,
} from '@pankod/refine-core'
import { ModalProps } from '@pankod/refine-antd'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import Modal from '../modal'
import Cross from '../image/x.svg'
import styled from 'styled-components'
import { useAddPaymentState } from './model/useAddPayment'

interface Props extends ModalProps {
  controlsVariantPay: [
    TypePaymentVariant | null,
    Dispatch<TypePaymentVariant | null>,
  ]
  closeModal: () => void
  errorHasPayment: boolean
  controlsNowStep: [number, Dispatch<number>]
  iconsData?: IPaymentsType[]
  setErrorHasPayment: Dispatch<SetStateAction<boolean>>
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<GetListResponse<IPaymentType>, HttpError>>
}

const initialState: PayService = {
  id: '',
  service: '',
  comment: '',
  num: '',
}

const ModalContent = ({
  controlsVariantPay: [variantPay, setVariantPay],
  closeModal,
  errorHasPayment,
  controlsNowStep: [nowStep, setNowStep],
  iconsData = [],
  setErrorHasPayment,
  // addPayment,
  refetch,
  ...props
}: Props) => {
  const [templatePay, setTemplatePay] = useState<PayService>(initialState)
  const [isValid, setIsValid] = useState(false)
  const [clickSubmit, setClickSubmit] = useState(false)
  const { t } = useTranslation()
  const { mutate } = useCreate<{ data: IPaymentType }>({})
  const { addPayment: addPaymentReq } = useAddPaymentState()
  useEffect(() => {
    nowStep === 2 && setTemplatePay(initialState)
  }, [nowStep])

  useEffect(() => {
    setClickSubmit(false)
  }, [templatePay, nowStep])

  const addPayment = (
    type: keyof Payments,
    comment?: string,
    serviceType?: string,
    num?: string,
    email?: string,
    bik?: string,
  ): 'success' | 'error' => {
    let status: 'success' | 'error'

    const onSuccess = async () => {
      await refetch?.()
      closeModal()
      notification.success({
        message: t('payments.notification.successAddTitle').toString(),
        description: t(
          'payments.notification.successAddDescription',
        ).toString(),
      })
      setErrorHasPayment(false)
      setTemplatePay(initialState)
    }

    const onError = (error: HttpError) => {
      ;[
        'The card identifier has already been taken.',
        'The web service account id has already been taken.',
        'The phone has already been taken.',
        'The bank account number has already been taken.',
      ].includes(error?.message) && setErrorHasPayment(true)
    }

    const addPaymentReqFull = (
      type: string,
      values: Record<string, string | undefined>,
    ) => {
      addPaymentReq(type, values)
        .then(() => onSuccess())
        .catch((e) => {
          onError(e)
        })
    }

    switch (type) {
      case 'sbp':
        addPaymentReqFull('spb', {
          commentary: comment,
          phone: num,
        })
        break
      case 'banks':
        addPaymentReqFull('banks', {
          bank_id: serviceType,
          commentary: comment || 'null',
          account_office: email || 'null',
          bank_account_number: num,
          bank_identification_code: bik,
        })
        break
      case 'mobiles':
        addPaymentReqFull('mobiles', {
          commentary: comment,
          phone: num,
        })
        break
      case 'cashes':
        addPaymentReqFull('cashes', {
          commentary: comment,
        })
        break
      case 'cards':
        addPaymentReqFull('cards', {
          commentary: comment,
          bank_id: serviceType,
          card_identifier: num?.replace(/\s/g, ''),
        })
        break
      case 'services':
        addPaymentReqFull('services', {
          commentary: comment,
          web_service_name: serviceType === 'ЮMoney' ? 'YooMoney' : serviceType,
          web_service_account_id: num,
          ...(serviceType === 'AdvCash'
            ? {
                email,
              }
            : {}),
        })
        break
    }

    status = errorHasPayment ? 'error' : 'success'
    return status
  }

  return (
    <Modal
      style={{ height: 640 }}
      closeIcon={<img alt={'cross'} src={Cross} />}
      open={props.open}
      onOk={props.onOk}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      title={t('payments.addingAPaymentMethod')}
      onCancel={closeModal}
    >
      <ModalContainer>
        <Stepper nowStep={nowStep} />
        {nowStep === 1 || !variantPay ? (
          <FirstStep controlsVariant={[variantPay, setVariantPay]} />
        ) : (
          <SecondStep
            errorHasPayment={errorHasPayment}
            clickSubmit={clickSubmit}
            setIsValid={setIsValid}
            controlsTemplatePay={[templatePay, setTemplatePay]}
            variantPayment={variantPay}
            iconsData={iconsData}
          />
        )}
        <Footer
          setClickSubmit={setClickSubmit}
          addPayment={() => {
            if (isValid && variantPay) {
              const status = addPayment(
                variantPay,
                templatePay.comment || undefined,
                templatePay.service || 'null',
                templatePay.num || 'null',
                templatePay.email || 'null',
                templatePay.bik || 'null',
              )
            }
          }}
          controlsNowStep={[nowStep, setNowStep]}
          controlsVariantPay={[variantPay, setVariantPay]}
          closeModal={closeModal}
          setErrorHasPayment={setErrorHasPayment}
        />
      </ModalContainer>
    </Modal>
  )
}

export type TypePaymentVariant = keyof Payments

export default ModalContent

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
