import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import './index.css';
import {
  useCreate,
  useTranslate,
} from '@pankod/refine-core';
import {
  IGetListAdData,
  IPaymentDetail,
  IPaymentsType,
  IRegionTemp,
} from './interface';
import * as _ from 'lodash';
import { isNumber } from 'lodash';
import { PaymentTypeEnum } from '../../../features/p2p/createOrder/types';
import { IP2PUser } from '../../../interfaces';
import { toFixed } from '../../../shared/helpers/toFixed';
import { Collapse as CollapseCustom } from 'widgets/p2p/buyOrder/collapse/index';
import { useAuthState } from '../../../useAuthState';
import { useCookiesCustom } from '../../../shared/hooks/useCookieCustom';
import { buyPaymentOptions, paymentOptions } from './lib/preparePaymentOptions';
import { useUserPaymentMethods } from './client/userPaymentMethods';

interface ICustomCollapse {
  type: 'buy' | 'sell';
  advData: IGetListAdData;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setFromCard: Dispatch<SetStateAction<boolean>>;
  user?: IP2PUser;
  iconsData?: IPaymentsType[];
  regions?: IRegionTemp[];
}

const CustomCollapse: FC<ICustomCollapse> = ({
  type = 'buy',
  advData,
  user,
  setOpenModal,
  setFromCard,
  iconsData,
  regions
}) => {
  const t = useTranslate();
  const { cookie } = useCookiesCustom();
  const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid'];
  const userAPI = useAuthState((state) => state?.userAPI);
  const isOwner = advData?.owner_id === uid;
  const [isOpen, setIsOpen] = useState(false);

  const [userPaymentMethods, fetchUserPaymentMethods] = useUserPaymentMethods(uid)

  useCreate<{ data: { id: string } }>({
    mutationOptions: { retry: false },
  });

  const handleOpen = async () => {
    setIsOpen(() => !isOpen);
    if (!isOpen && type === 'buy') {
      void fetchUserPaymentMethods();
    }
  };

  // TODO: зарефачить countDecimalPlaces через BN
  function countDecimalPlaces(number: number) {
    const numberString = number.toString();
    const decimalIndex = numberString.indexOf('.');
    if (decimalIndex === -1) {
      return 0;
    } else {
      return numberString.length - decimalIndex - 1;
    }
  }

  // TODO: зарефачить applyLimit через BN
  const applyLimit = (
    limit: string,
    decimalPlaces: number,
    direction: 'min' | 'max' | ''
  ): string => {
    const result = +limit / +advData?.price;
    const decimalIndex = countDecimalPlaces(result);

    if (decimalIndex <= decimalPlaces) {
      return result.toString();
    } else {
      const splitIndex = decimalPlaces + 1;
      if (direction === 'min') {
        const minCeil = (result + 0.00000001).toFixed(splitIndex);
        return minCeil.slice(0, minCeil.length - 1);
      }
      if (direction === 'max') {
        const maxFloor = result.toFixed(splitIndex);
        return maxFloor.slice(0, maxFloor.length - 1);
      } else return result.toFixed(decimalPlaces).toString();
    }
  };

  const typeText = useMemo(() => {
    return {
      text: t(`dashboard.collapseHeader.button.${type}`),
      input: [
        t(`dashboard.collapseBody.${type}.first`),
        t(`dashboard.collapseBody.${type}.last`),
      ],
      placeholder: `${applyLimit(
        advData?.min_sum_limit,
        advData?.ccy === 'USDT' ? 2 : 8,
        'min'
      )} - ${applyLimit(
        advData?.max_sum_limit,
        advData?.ccy === 'USDT' ? 2 : 8,
        'max'
      )}`,
      sellMin: +applyLimit(
        advData?.min_sum_limit,
        advData?.ccy === 'USDT' ? 2 : 8,
        'min'
      ),
      sellMax: +applyLimit(
        advData?.max_sum_limit,
        advData?.ccy === 'USDT' ? 2 : 8,
        'max'
      ),
    };
  }, [
    advData.max_sum_limit,
    advData.min_sum_limit,
    advData.price,
    advData.ccy,
    t,
    type,
  ]);

  const totalOrdersAmount = isOwner
    ? userAPI?.p2p_total_orders_amount
    : isNumber(advData?.owner_info?.total_orders_amount)
      ? advData?.owner_info?.total_orders_amount
      : 0;
  const successOrdersAmount = isOwner
    ? userAPI?.p2p_success_orders_amount
    : isNumber(advData?.owner_info?.success_orders_amount)
      ? advData?.owner_info?.success_orders_amount
      : 0;

  const countDeals = () => {
    const totalAmount = Number(totalOrdersAmount) || 0
    const successAmount = Number(successOrdersAmount) || 0

    return `${totalAmount} ${t('dashboard.collapseHeader.countDeals')}` +
      ` | ${toFixed(successAmount / (totalAmount || 1) * 100)}%` +
      ` ${t('dashboard.collapseHeader.countCompleted')}`;
  };
  
  const getPaymentData = (item: IPaymentDetail & {
    payment_type: PaymentTypeEnum
    payment_details?: IPaymentDetail
  }) => {
    const paymentDetails = item.payment_details || {};

    const bankName = paymentDetails
      ? paymentDetails?.bank_name
      : item.bank_name;

    return {
      id: item.id,
      title: bankName,
      web_service_name: item.payment_type === PaymentTypeEnum.webServicePayment
        ? paymentDetails.web_service_name
        : bankName,
      payment_type: item.payment_type,
      phone: item.phone,
      bank_name: item.bank_name,
      bank_id: item.bank_id
    };
  };

  const methodArray = useCallback(
    (icon: boolean = false, method = false) => {
      const advBuyMethods = advData.buy_payment_info

      if (advData.type === 'buy') {
        if (!method || !isOpen || userPaymentMethods?.length === undefined) {
          return buyPaymentOptions({ data: advBuyMethods, icon, iconsData, advBuyMethods, t });
        }

        const userMethodsMod = userPaymentMethods?.map((el) => {
          return {
            ...el,
            payment_details: el.payment_details[0],
          }
        })

        const fitleredItems = userMethodsMod && advBuyMethods
          ? userMethodsMod.filter(userMethod => (
            advBuyMethods.some(advMethod => (
              advMethod.payment_type === userMethod.payment_type &&
              (!userMethod.bank_name || !advMethod.bank_name || advMethod.bank_name === userMethod.bank_name) &&
              (!userMethod.web_service_name || !advMethod.web_service_name || advMethod.web_service_name === userMethod.web_service_name))
            )
          ))
          : advBuyMethods ?? []
        const matchedItems = fitleredItems.map(getPaymentData)

        return buyPaymentOptions({ data: matchedItems, icon, iconsData, t, advBuyMethods })
      } else if (advData.type === 'sell') {
        return paymentOptions({
          data: advData.payment_info,
          icon,
          iconsData,
          t
        });
      }

      return []
    },
    [isOpen, userPaymentMethods?.length, advData, buyPaymentOptions, paymentOptions]
  );

  return (
    <CollapseCustom
      regions={regions ? regions : []}
      isOpen={isOpen}
      user={user}
      setOpenModal={setOpenModal}
      setFromCard={setFromCard}
      typeText={typeText}
      dataAd={advData}
      countDeals={countDeals()}
      methodArray={methodArray}
      onClickOpen={handleOpen}
      setIsOpen={setIsOpen}
      item={advData}
    />
  );
};

export default CustomCollapse;
