import React from 'react'
import { useTranslate } from '@pankod/refine-core'
import IconOld from '@ant-design/icons'

import { PaymentTypeEnum } from 'features/p2p/createOrder/types'
import { IGetListAdData, IPaymentDetail, IPaymentInfo, IPaymentsType } from '../interface'
import { MobileMethod, BankCredention, FPS } from 'shared/components/icons'
import { transformPhone } from 'shared/helpers/phones'
import { IP2PCollapseArrayElement } from 'widgets/p2p/buyOrder/collapse/interface'
import { LabelStyled } from '../collapse.styles'
import { ImgIcon } from '../ui/ImgIcon'

export const buyPaymentOptions = ({
  data = [],
  icon = false,
  iconsData = [],
  advBuyMethods = [],
  t,
}: {
  data?: (IPaymentDetail & {
    payment_type: PaymentTypeEnum;
    id?: number;
    title?: string;
    phone?: string;
    bank_name?: string;
    web_service_name?: string;
    bank_id?: number;
  })[];
  icon?: boolean;
  advBuyMethods: IGetListAdData['buy_payment_info']
  iconsData?: IPaymentsType[]
  t: ReturnType<typeof useTranslate>
}) => {
  return data?.map((item): IP2PCollapseArrayElement => {
    switch (item.payment_type) {
      case PaymentTypeEnum.bankCardPayment:
        return {
          value: item.id || item?.bank_id,
          label: icon ? (
            <LabelStyled key={item.payment_type + '_' + item.id}>
              <ImgIcon
                payment_type={PaymentTypeEnum.bankCardPayment}
                name={
                  item.bank_name ??
                  advBuyMethods?.find((ic) => ic.id === item.id)?.bank_name ??
                  (advBuyMethods?.find((ic) => ic.bank_name === item.title)
                    ?.bank_name ||
                    '')
                }
                iconsData={iconsData}
              />
              {t(`banks.${item.bank_name || item?.title}`)}
            </LabelStyled>
          ) : (
            <React.Fragment key={item.payment_type + '_' + item.id}>
              {t(`banks.${item.bank_name || item?.title}`)}
            </React.Fragment>
          ),
          title: t(`banks.${item.bank_name || item?.title}`),
          type: t('payments.types.banksCard'),
        };
      case PaymentTypeEnum.mobileBalancePayment:
        return {
          value: item.id,
          label: icon ? (
            <LabelStyled key={item.payment_type + '_' + item.id}>
              <IconOld component={() => MobileMethod('', 16)} />
              {t('dashboard.filters.method.other.methods.mobile')}
            </LabelStyled>
          ) : (
            <React.Fragment key={item.payment_type + '_' + item.id}>
              {t('dashboard.filters.method.other.methods.mobile')}
            </React.Fragment>
          ),
          title:
            transformPhone(item?.phone ? item?.phone : '') ||
            t('payments.types.mobiles'),
          type: t('payments.types.mobiles'),
        };
      case PaymentTypeEnum.cashPayment:
        return {
          value: item.id,
          label: 'Cash Payment',
          type: t('payments.types.cashes'),
        };
      case PaymentTypeEnum.bankTransferPayment:
        return {
          value: item.id,
          label: icon ? (
            <LabelStyled key={item.payment_type + '_' + item.id}>
              <IconOld component={() => BankCredention('#FBFBFF', 16)} />
              {t('dashboard.filters.method.other.methods.bank')}
            </LabelStyled>
          ) : (
            <React.Fragment key={item.payment_type + '_' + item.id}>
              {t('dashboard.filters.method.other.methods.bank')}
            </React.Fragment>
          ),
          title: t(`banks.${item.bank_name ?? item?.title}`),
          type: t('dashboard.filters.method.other.methods.bank'),
        };
      case PaymentTypeEnum.fastPaymentSystemPayment:
        return {
          value: item.id,
          label: icon ? (
            <LabelStyled key={item.payment_type + '_' + item.id}>
              <IconOld component={() => FPS('', 16)} />
              {t('dashboard.filters.method.other.methods.fps')}
            </LabelStyled>
          ) : (
            <React.Fragment key={item.payment_type + '_' + item.id}>
              {t('dashboard.filters.method.other.methods.fps')}
            </React.Fragment>
          ),
          type: t('payments.types.sbp'),
        };
      case PaymentTypeEnum.webServicePayment:
        return {
          value: item.id,
          label: icon ? (
            <LabelStyled key={item.payment_type + '_' + item.id}>
              <ImgIcon
                payment_type={PaymentTypeEnum.webServicePayment}
                name={item.web_service_name!}
                iconsData={iconsData}
              />
              {item.web_service_name}
            </LabelStyled>
          ) : (
            <React.Fragment key={item.payment_type + '_' + item.id}>
              {item.web_service_name}
            </React.Fragment>
          ),
          title: item.web_service_name,
          type: t('payments.electronService'),
        };
      default:
        return {
          value: item.id,
          label: '',
          type: '',
        };
    }
  });
}

export const paymentOptions = ({
  data = [],
  icon = false,
  iconsData,
  t
}: {
  data?: IPaymentInfo[];
  icon?: boolean;
  iconsData?: IPaymentsType[]
  t: ReturnType<typeof useTranslate>
}) => {
  return (
    data?.map((item) => {
      switch (item.payment_type) {
        case PaymentTypeEnum.bankCardPayment:
          return (
            item.payment_details?.map((bank) => {
              return {
                value: icon ? item.id : undefined,
                label: icon ? (
                  <LabelStyled key={item.payment_type + '_' + item.id}>
                    <ImgIcon
                      payment_type={PaymentTypeEnum.bankCardPayment}
                      name={bank.bank_name!}
                      iconsData={iconsData}
                    />
                    {t(`banks.${bank.bank_name}`)}
                  </LabelStyled>
                ) : (
                  <React.Fragment key={item.payment_type + '_' + item.id}>
                    {t(`banks.${bank.bank_name}`)}
                  </React.Fragment>
                ),
                title: t(`banks.${bank.bank_name}`),
                type: t('payments.types.banks'),
              };
            }) ?? []
          );
        case PaymentTypeEnum.mobileBalancePayment:
          return (
            item.payment_details?.map(() => {
              return {
                value: item.id,
                label: icon ? (
                  <LabelStyled key={item.payment_type + '_' + item.id}>
                    <IconOld component={() => MobileMethod('', 16)} />
                    {t(
                      'dashboard.filters.method.other.methods.mobile'
                    )}
                  </LabelStyled>
                ) : (
                  <React.Fragment key={item.payment_type + '_' + item.id}>
                    {t(
                      'dashboard.filters.method.other.methods.mobile'
                    )}
                  </React.Fragment>
                ),
                // @ts-ignore-next-line
                title: transformPhone(item?.phone),
                type: t('payments.types.mobiles'),
              };
            }) ?? []
          );
        case PaymentTypeEnum.cashPayment:
          return (
            item.payment_details?.map(() => {
              return {
                value: item.id,
                label: 'Cash Payment',
                type: t('payments.types.cashes'),
              };
            }) ?? []
          );
        case PaymentTypeEnum.bankTransferPayment:
          return (
            item.payment_details?.map((transfer) => {
              return {
                value: item.id,
                label: icon ? (
                  <LabelStyled key={item.payment_type + '_' + item.id}>
                    <IconOld
                      component={() => BankCredention('#FBFBFF', 16)}
                    />
                    {t(`banks.${transfer?.bank_name}`)}
                  </LabelStyled>
                ) : (
                  <React.Fragment key={item.payment_type + '_' + item.id}>
                    {t(`banks.${transfer?.bank_name}`)}
                  </React.Fragment>
                ),
                // @ts-ignore-next-line
                title: t(`banks.${transfer?.bank_name || item?.title}`),
                type: t(
                  'dashboard.filters.method.other.methods.bank'
                ),
              };
            }) ?? []
          );
        case PaymentTypeEnum.fastPaymentSystemPayment:
          return (
            item.payment_details?.map(() => {
              return {
                value: item.id,
                label: icon ? (
                  <LabelStyled key={item.payment_type + '_' + item.id}>
                    <IconOld component={() => FPS('', 16)} />
                    {t(
                      'dashboard.filters.method.other.methods.fps'
                    )}
                  </LabelStyled>
                ) : (
                  <React.Fragment key={item.payment_type + '_' + item.id}>
                    {t(
                      'dashboard.filters.method.other.methods.fps'
                    )}
                  </React.Fragment>
                ),
                type: t('payments.types.sbp'),
              };
            }) ?? []
          );
        case PaymentTypeEnum.webServicePayment:
          return (
            item.payment_details?.map((web) => {
              return {
                value: item.id,
                label: icon ? (
                  <LabelStyled key={item.payment_type + '_' + item.id}>
                    <ImgIcon
                      payment_type={PaymentTypeEnum.webServicePayment}
                      name={web.web_service_name!}
                      iconsData={iconsData}
                    />
                    {web.web_service_name}
                  </LabelStyled>
                ) : (
                  <React.Fragment key={item.payment_type + '_' + item.id}>
                    {web.web_service_name}
                  </React.Fragment>
                ),
                title: web.web_service_name,
                type: t('payments.electronService'),
              };
            }) ?? []
          );
      }

      return [];
    }).flat() ?? []
  );
}
