import {useState} from "react";
import {Button, Popconfirm, Tooltip} from '@pankod/refine-antd';
import {useUpdate} from '@pankod/refine-core';
import {useTranslation} from 'react-i18next';
import {useNavigate} from '@pankod/refine-react-router-v6';

import {CloseIcon, File, Pencil, UploadIco} from 'shared/components/icons';
import styled from "styled-components";
import {Trash} from "../../../../shared/components/ui/assets/svg/Trash";
import {notification} from "antd";

import './announcementButtons.css';

const popConfirmProps = {
    okButtonProps: {
        style: {paddingInline: 32},
        size: "large" as const,
    },
    cancelButtonProps: {
        style: {
            paddingInline: 32,
            background: 'none',
            color: 'white',
            borderColor: '#7B59FD',
        },
        size: "large" as const,
    },
}

const TooltipOverlayStyle = {minWidth: '5%', width: 'fit-content', maxWidth: 'fit-content'}

const PopconfirmOverlayInnerStyle = {minWidth: '5%', width: '100%', maxWidth: 'fit-content', marginRight: '30px'}

export const AnnButtons = ({
                               status,
                               disabled,
                               itemId,
                               itemType,
                               itemCcy,
                               itemFiat
                           }: {
    status: string;
    disabled: boolean;
    itemId: number | undefined;
    itemType: string;
    itemCcy: string;
    itemFiat: string;
}) => {
    const {t} = useTranslation();
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isOpenDraft, setIsOpenDraft] = useState(false);
    const [isOpenUpload, setIsOpenUpload] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);

    const handleEdit = () => {
        setIsOpenDelete(false);
        setIsOpenUpload(false);
        setIsOpenDraft(false);
        setIsOpenEdit(!isOpenEdit);
    }
    const handleDraft = () => {
        setIsOpenEdit(false);
        setIsOpenUpload(false);
        setIsOpenDelete(false);
        setIsOpenDraft(!isOpenDraft);
    }
    const handleUpload = () => {
        setIsOpenEdit(false);
        setIsOpenDraft(false);
        setIsOpenDelete(false);
        setIsOpenUpload(!isOpenUpload);
    }
    const handleDelete = () => {
        setIsOpenEdit(false);
        setIsOpenDraft(false);
        setIsOpenUpload(false);
        setIsOpenDelete(!isOpenDelete);
    }

    return (
        <div className="flex-row gap-16">
            <AnnButton
                itemId={itemId}
                disabled={status === 'executed' ? true : disabled}
                type={'edit'}
                title={t('announcements.buttons.editAd0')}
                status={status}
                itemType={itemType}
                itemCcy={itemCcy}
                itemFiat={itemFiat}
                isOpenPopconfirm={isOpenEdit}
                setIsOpenPopconfirm={handleEdit}
            />
            {status !== 'template' ? (
                <AnnButton
                    itemFiat={itemFiat}
                    itemCcy={itemCcy}
                    status={status}
                    itemId={itemId}
                    disabled={status === 'executed' ? true : disabled}
                    type={'draft'}
                    title={t('announcements.buttons.moveToDrafts')}
                    isOpenPopconfirm={isOpenDraft}
                    setIsOpenPopconfirm={handleDraft}
                />
            ) : (
                <AnnButton
                    itemFiat={itemFiat}
                    itemCcy={itemCcy}
                    status={status}
                    itemId={itemId}
                    disabled={disabled}
                    type={'upload'}
                    title={t('announcements.buttons.postAd')}
                    isOpenPopconfirm={isOpenUpload}
                    setIsOpenPopconfirm={handleUpload}
                />
            )}
            <AnnButton
                itemFiat={itemFiat}
                itemCcy={itemCcy}
                itemId={itemId}
                disabled={disabled}
                type={'delete'}
                title={t('announcements.buttons.deleteAd0')}
                isOpenPopconfirm={isOpenDelete}
                setIsOpenPopconfirm={handleDelete}
            />
        </div>
    );
};

const AnnButton = ({
                       type,
                       title,
                       disabled,
                       itemId,
                       status,
                       itemType,
                       itemCcy,
                       itemFiat,
                       isOpenPopconfirm,
                       setIsOpenPopconfirm
                   }: {
    type: string;
    title: string;
    disabled?: boolean;
    itemId: number | undefined;
    status?: string;
    itemType?: string;
    itemCcy?: string;
    itemFiat?: string;
    isOpenPopconfirm: boolean;
    setIsOpenPopconfirm: (value: boolean) => void;
}) => {
    const {t} = useTranslation();
    const {mutate} = useUpdate();
    const navigate = useNavigate();

    const openNotification = ({type, message, description, duration}: any) => {
        if (type === 'success') {
            notification.success({
                message,
                description,
                duration,
                closeIcon: <CloseIcon/>,
            });
        } else {
            notification.error({
                message,
                description,
                duration,
            });
        }
    };

    const changeStatus = (deleteAnn?: boolean) => {
        let newStatus = '';
        deleteAnn ? (newStatus = 'deleted') : status === 'template' ? (newStatus = 'created') : (newStatus = 'template');

        try {
            mutate({
                resource: 'offer',
                dataProviderName: 'p2p',
                id: `${itemId}`,
                values: {status: `${newStatus}`},
            }, {
                onSuccess: (data) => {
                    if (newStatus === 'template') {
                        openNotification({
                            type: 'success',
                            message: t('Объявление снято с публикации'),
                            duration: 3,
                            description: t(`Объявление id снято с публикации`, {
                                itemId: itemId,
                                itemCcy: itemCcy,
                                itemFiat: itemFiat
                            }),
                        })
                    } else if (newStatus === 'created') {
                        openNotification({
                            type: 'success',
                            // TODO: Добавить нормальный текст для уведомления https://sputnik-soft.atlassian.net/browse/PHEX-5297
                            message: t('Объявление опубликовано'),
                            duration: 3,
                            description: t(`Объявление id было опубликовано`, {
                                itemId: itemId,
                                itemCcy: itemCcy,
                                itemFiat: itemFiat
                            }),
                        })
                    } else if (newStatus === 'deleted') {
                        openNotification({
                            type: 'success',
                            message: t('Объявление удалено'),
                            duration: 3,
                            description: t(`Объявление id удалено`, {
                                itemId: itemId,
                                itemCcy: itemCcy,
                                itemFiat: itemFiat
                            }),
                        })
                    }
                },
                onError: (error, updateParams) => {
                    const oldStatus = (updateParams.values as Record<string, unknown>).status as string
                    const errorMessage = t(error.message, '')

                    if (!errorMessage) {
                        console.error(error.message, error);
                    }

                    let notificationProps: {
                        message: string
                        description: string
                    } = {
                        message: t('messages.error.dataUpdateError'),
                        description: errorMessage || error.message
                    }

                    if (oldStatus === 'template') {
                        notificationProps = {
                            message: t('Ошибка снятия объявления с публикации'),
                            description: errorMessage ||  t(`Объявление id не удалось снять с публикации`, {
                                itemId: itemId,
                                itemCcy: itemCcy,
                                itemFiat: itemFiat
                            })
                        }
                    } else if (oldStatus === 'created') {
                        notificationProps = {
                            message: t('Ошибка публикации объявления'),
                            description: errorMessage || t(`Объявление id не удалось опубликовать`, {
                                itemId: itemId,
                                itemCcy: itemCcy,
                                itemFiat: itemFiat
                            }),
                        }
                    } else if (oldStatus === 'deleted') {
                        notificationProps = {
                            message: t('Ошибка удаления объявления'),
                            description: errorMessage || t(`Ошибка удаления объявление id`, {
                                itemId: itemId,
                                itemCcy: itemCcy,
                                itemFiat: itemFiat
                            }),
                        }
                    }

                    if (error.message === 'Unable to publish Ad, you already have one') {
                        notificationProps.description = t('messages.error.maxAdLimit')
                    } else if (error.message === 'The max_sum_limit must not exceed the total sum') {
                        notificationProps.description = t('messages.error.maxLimitExceed')
                    }

                    openNotification({
                        type: 'error',
                        duration: 3,
                        ...notificationProps
                    })
                }
            });
        } catch (e) {
            console.error('Change status error', e)  
        }

        setIsOpenPopconfirm(false)
    };

    const handleEditButton = (type: string) => {
        type !== 'edit'
            ? changeStatus()
            : navigate(`/p2p/edit-offer/${itemType}/${itemId}/${itemCcy}${itemFiat}`);
    }

    const iconMap = () => {
        switch (type) {
            case 'edit':
                return <Pencil/>;
            case 'draft':
                return <File/>;
            case 'upload':
                return <UploadIco/>;
            default:
                return <Trash/>;
        }
    };

    const titleMap = () => {
        switch (type) {
            case 'edit':
                return t('announcements.buttons.editAd') + ' ?';
            case 'draft':
                return t('announcements.buttons.moveToDrafts') + ' ?';
            case 'upload':
                return t('announcements.buttons.postAd') + ' ?';
            default:
                return t('announcements.buttons.deleteAd') + ' ?';
        }
    }

    const handleOnCancelPopconfirm = () => {
        return setIsOpenPopconfirm(false);
    }

    const onClickButtonHandler = () => {
        setIsOpenPopconfirm(true);
    }

    const onConfirmPopconfirm = () => {
        return type === 'delete' ? changeStatus(true) : handleEditButton(type)
    }

    return (
        <Popconfirm
            title={titleMap()}
            onCancel={handleOnCancelPopconfirm}
            open={isOpenPopconfirm}
            onConfirm={onConfirmPopconfirm}
            cancelText={t('payments.popConfirm.No')}
            okText={t('payments.popConfirm.Yes')}
            overlayInnerStyle={PopconfirmOverlayInnerStyle}
            {...popConfirmProps}
        >
            {isOpenPopconfirm && (
                <Button
                    className={disabled ? 'disabled ann-button' : 'ann-button'}
                    onClick={onClickButtonHandler}
                    disabled={disabled}
                    type={'link'}
                >
                    {iconMap()}
                </Button>
            )}
            {!isOpenPopconfirm && (
                <Tooltip
                    overlayInnerStyle={{width: 'fit-content', border: '1px solid #767687', backgroundColor: ''}}
                    placement="bottomRight"
                    title={
                        <TooltipTitleStyled>
                            {disabled ? t('announcements.status.haveOpenDeals') : title}
                        </TooltipTitleStyled>
                    }
                    overlayStyle={TooltipOverlayStyle}
                >
                    <ButtonStyled
                        className={disabled ? 'disabled ann-button' : 'ann-button'}
                        onClick={onClickButtonHandler}
                        disabled={disabled}
                        type={'link'}
                    >
                        {iconMap()}
                    </ButtonStyled>
                </Tooltip>
            )}
        </Popconfirm>
    );
};

const ButtonStyled = styled(Button)`
    background: transparent;
`;

const TooltipTitleStyled = styled.div`
    display: flex;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    align-content: center;
`;

