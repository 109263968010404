import { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react'
import { AdditionalPriceRange } from '../model/types'
import { Input, InputRef } from 'antd'
import BN from 'bignumber.js'
import { FIAT_DP } from '../../../lib/constants'

interface IProps {
  className?: string
  adPriceValue: number
  changeAdPriceValue(value: number): void
  isDisabled?: boolean
  priceRange: AdditionalPriceRange
  postfix?: string
}

/**
 *  Есть несколько кейсов для направления данных:
 *  1) юзер ввёл корректную цифру - обновляем adPriceValue (внешний state) без обновления строки инпута
 *  2) по фокусу - убирается ключ валюты в строке инпута
 *  3) если adPriceValue обновился уровнем выше - обновляем текущий инпут
 *  4) юзер убрал фокус - выставялем отформатированную строку из значения adPriceValue + postfix
 */
const AdPriceInput: FC<IProps> = ({
  className,
  adPriceValue,
  changeAdPriceValue,
  isDisabled,
  priceRange,
  postfix,
}) => {
  const inputRef = useRef<InputRef>(null)
  const isFocusRef = useRef<boolean>(false)
  const selectIndexRef = useRef(0)
  const [priceString, setPriceString] = useState('')

  const onFocus = () => {
    isFocusRef.current = true
    setPriceString(adPriceValue.toString())
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    selectIndexRef.current = e.target.selectionStart ?? 0

    setPriceString(e.target.value)
    const currentValue = e.target.value
    const numberPattern = /\d*\.?\d*/

    const matchedNumber = numberPattern.exec(currentValue)?.[0]

    let numberValue = matchedNumber
      ? BN(matchedNumber).dp(FIAT_DP, BN.ROUND_FLOOR)
      : priceRange.min

    const ltMin = numberValue.comparedTo(priceRange.min) === -1
    const gtMax = numberValue.comparedTo(priceRange.max) === 1

    if (ltMin) {
      numberValue = priceRange.min
    } else if (gtMax) {
      numberValue = priceRange.max
    }

    changeAdPriceValue(numberValue.toNumber())
  }

  useEffect(() => {
    if (isFocusRef.current) {
      return
    }

    setPriceString(`${adPriceValue}${postfix}`)
  }, [adPriceValue, postfix])

  useEffect(() => {
    const nextPos = selectIndexRef.current ?? 0
    inputRef.current?.input?.setSelectionRange(nextPos, nextPos)
  }, [priceString])

  const onBlur: React.FocusEventHandler<HTMLInputElement> = (_) => {
    setPriceString(`${adPriceValue}${postfix}`)
    isFocusRef.current = false
  }

  return (
    <Input
      className={className}
      value={priceString}
      disabled={isDisabled}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={inputRef}
    />
  )
}

export default AdPriceInput
