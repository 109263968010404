import { FC, useEffect, useMemo, useState } from 'react'
import BN from 'bignumber.js'
import { shallow } from 'zustand/shallow'
import {
  ConfigProvider,
  Form,
  Typography,
} from '@pankod/refine-antd'
import { useNavigate } from '@pankod/refine-react-router-v6'
import { useCreate, useTranslate } from '@pankod/refine-core'
import { styled } from 'styled-components'

import { useAuthState } from 'useAuthState'
import Button from 'shared/components/ui/button'
import { ButtonsTypes } from 'shared/components/ui/button/filledButton.p'
import CustomSelect from 'shared/components/ui/select'
import { SwitchTwoElem } from 'shared/components/ui/switchTwoElem'
import { useGetConfigCommision } from 'shared/hooks/currency-info/use-get-config-commision'
import { useCookiesCustom } from 'shared/hooks/useCookieCustom'
import { IP2PCollapseInputData } from './interface'

import { DealQuantitySlider } from './deaQuantitySlider'
import { ADV_TYPE, CRYPTO_DP, CRYPTO_ROUNDING, FIAT_DP, FIAT_ROUNDING } from './lib/constants'
import { useDealCreateStore } from './hooks/useDealCreateState'
import { useShallowValue } from './hooks/useShallowValue'
import { useShallowState } from './hooks/useShallowState'
import { useBalances } from './hooks/useBalances'
import { DealQuantityInput } from './dealQuantityInput'

const INPUT_NAME = 'deal-sum-input'

export const CollapseOpenInputData: FC<IP2PCollapseInputData> = ({
  dataAd,
  typeText, // Текстовки над инпутом цены. EX: для продажи (Хочу продать, Хочу получить)
  setIsOpen,
  methodArray,
  item,
  setFromCard,
  setOpenModal,
  user,
}) => {
  const { cookie } = useCookiesCustom()
  const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid']
  const isAuth = !!cookie['token']?.length
  const login = useAuthState((state) => state.login)
  const register = useAuthState((state) => state.register)

  // используется для отображения ошибок только после сабмита
  const [isSubmitted, setSubmitted] = useState(false)
  
  const useDealCreateState = useDealCreateStore()
  
  const updateState = useDealCreateState(state => state.actions.update)

  const output = useDealCreateState(useShallowState(state => ({
    ...state.actions.getOutput()
  })))

  const state = useDealCreateState(useShallowState(state => ({
    minFiat: state.minFiat,
    maxFiat: state.maxFiat,
    minCrypto: state.minCrypto,
    maxCrypto: state.maxCrypto,
    fiatSum: state.fiatSum,
    cryptoSum: state.cryptoSum,
    pricePerCoin: state.pricePerCoin
  })))

  // Отслеживаем изменения только части dataAd (не обязательно)
  const dataTransformed = useShallowValue({
    advType: dataAd.type,
    minFiat: dataAd.min_sum_limit,
    maxFiat: dataAd.max_sum_limit,
    pricePerCoin: dataAd.price,
  })
  
  useEffect(() => {
    dataTransformed && updateState(dataTransformed)
  }, [dataTransformed])

  const navigate = useNavigate()
  const t = useTranslate()
  const { configData } = useGetConfigCommision({ configKey: 'p2p_commission' })

  const [selectData, setSelectData] = useState('')

  const { mutate } = useCreate<{ data: { id: string } }>({
    mutationOptions: { retry: false },
  })

  const isSelling = dataTransformed?.advType === ADV_TYPE.BUY
  
  // для продажи по умолчанию включаем фиатный эквивалент
  const [isFiatFlow, setIsFiatFlow] = useState(isSelling)

  const cryptoCode = dataAd.ccy
  const fiatCode = dataAd.fiat

  // балансы
  const { balanceCrypto, balanceInFiat } = useBalances(cryptoCode, state.pricePerCoin)

  const [errorState, setErrorState] = useState<Partial<{
    buy?: string
    payment?: string
  } | null>>(null)

  useEffect(() => {
    const newError: {
      buy?: string
      payment?: string
    } = {}

    const { current, min, max, dp, rounding } = isFiatFlow ? {
      current: state.fiatSum,
      min: state.minFiat,
      max: state.maxFiat,
      dp: FIAT_DP,
      rounding: FIAT_ROUNDING,
    } : {
      current: state.cryptoSum,
      min: state.minCrypto,
      max: state.maxCrypto,
      dp: CRYPTO_DP,
      rounding: CRYPTO_ROUNDING
    }

    const currentNumber = current.dp(dp, rounding).toNumber()
    const maxNumber = max.dp(dp, rounding).toNumber()
    const minNumber = min.dp(dp, rounding).toNumber()
  
    if (currentNumber < minNumber) {
      newError.buy = 'min'
    }
  
    if (currentNumber > maxNumber) {
      newError.buy = 'max'
    }
  
    if (!selectData) {
      newError.payment = 'err'
    }

    if (shallow(newError, errorState)) {
      return
    }

    setErrorState(Object.keys(newError).length ? newError : null)
  }, [state, selectData])
  
  const handleCreate = async () => {
    setSubmitted(true)

    if (!isAuth) {
      login()
      return
    }

    if (!user || errorState) {
      return
    }

    if (!user.nickname?.length) {
      setOpenModal(true)
      setFromCard(true)

      return
    }

    const currentCryptoSum = state.cryptoSum.dp(CRYPTO_DP, CRYPTO_ROUNDING)

    mutate(
      {
        dataProviderName: 'p2p',
        resource: 'order',
        values: {
          ad_id: dataAd.id,
          sum: currentCryptoSum.isNaN()
            ? undefined
            : currentCryptoSum.toNumber(),
          payment_id: selectData,
        },
      },
      {
        onSuccess: (res) => {
          navigate(`/p2p/order/${res.data.data.id}`)
        },
        onError: (res) => {
          // TODO: update errors handling
          // По факту мы не должны ориентироваться на строки с бэка (переводов тут нет)
          if (
            res.response.data.message === 'Sum more then available trade amount'
          ) {
            setErrorState({ ...(errorState ?? {}), buy: 'max' })
          } else
            setErrorState(() => ({
              buy: res?.response?.data?.errors?.sum?.length
                ? res?.response?.data?.errors?.sum?.join('. ')
                : undefined,
              payment:
                res.message === 'The payment id field is required.'
                  ? 'The payment id field is required.'
                  : undefined,
            }))
        },
      },
    )
  }

  const handleCancel = () => {
    if (!isAuth) {
      register()
    } else {
      setIsOpen(false)
    }
  }

  // methodArray подготавливает список объектов методов оплаты
  const myPaymentMethods = useMemo(() => methodArray(true, true), [methodArray])
  const addMethodPayments = useMemo(() => methodArray(), [methodArray])

  const avaivaiblePaymentMethods = myPaymentMethods.filter((item) => {
    return addMethodPayments.some((it) => {
      return it.title === item.title && it.type === item.type
    })
  })

  const isNoAvaivailblePaymentMethod = avaivaiblePaymentMethods.length === 0
  const isNotEnoughBalance = balanceInFiat.comparedTo(state.minFiat) === -1
  const isMyAdvertisement = dataAd.owner_id === uid
  const isDisabledAdvertisement =
    isMyAdvertisement ||
    isNoAvaivailblePaymentMethod ||
    (isNotEnoughBalance && isSelling)

  const PaymentMethodHelperText = () => {
    if (isSubmitted && errorState?.payment) {
      return <RedText>{t('dashboard.collapseBody.paymentRequired')}</RedText>
    }

    if (avaivaiblePaymentMethods.length === 0) {
      return (
        <RedText>{t('dashboard.collapseBody.paymentNoAddMethods')}</RedText>
      )
    }

    return null
  }

  const FormWithCustomLabelHelpText = () => {
    if (!errorState?.buy) {
      return null
    }

    const isMinError = errorState?.buy === 'min'
    const errorText = t(`dashboard.collapseBody.${errorState?.buy}`)
    let value: BN
    const code = isFiatFlow ? fiatCode : cryptoCode

    /**
     * Если продаём, то надо сравнивать текущий баланс с максималкой и выводить минимальное из двух
     * 
     * EugeneB: похоже баг. По факту условие срабатывает только когда превышен максимальный лимит
     * При этом выводится сообщение об ошибке с текущим балансом крипты. Который может быть меньше минималки
     */
    // EugeneB: getCurrentMax вызывался тут
    if (isSelling && !isMinError) {
      const useBalance = balanceInFiat.comparedTo(state.maxFiat) === -1
      
      if (isFiatFlow) {
        value = useBalance ? balanceInFiat : state.maxFiat
      } else {
        value = useBalance ? balanceCrypto : state.maxCrypto
      }
    } else if (isMinError) {
      value = isFiatFlow ? state.minFiat : state.minCrypto
    } else {
      value = isFiatFlow ? state.maxFiat : state.maxCrypto
    }

    const dp = isFiatFlow ? FIAT_DP : CRYPTO_DP
    const rounding = isFiatFlow ? FIAT_ROUNDING : CRYPTO_ROUNDING
    const valueString = value.dp(dp, rounding).toString()

    return (
      <span className={'helpInput'}>
        {errorText} {valueString} {code}
      </span>
    )
  }

  // text input handler
  const getCustomInputPlaceholder = () => {
    return isFiatFlow
      ? typeText.placeholder
      : `${output.minFiat} - ${output.maxFiat}`
  }

  const getComission = () => {
    // Возможно баг. Но тут так было. Если есть конфиг - считаем комсу из крипты
    if (configData?.value) {
      const value = state.cryptoSum
        .times(configData?.value)
        .dp(CRYPTO_DP, CRYPTO_ROUNDING)
        .toString()

      return `${value} ${cryptoCode}`
    } else {
      const value = isFiatFlow ? output.minFiat : output.minCrypto

      return `${value} ${cryptoCode}`
    }
  }

  const getSummary = () => {
    const currencyCode = isFiatFlow ? fiatCode : cryptoCode
    
    let summaryString = ''

    if (!isSelling) {
      summaryString = state.fiatSum
        .plus(state.fiatSum.times(configData?.value ?? 0))
        .toFixed(FIAT_DP, FIAT_ROUNDING)
    } else {
      summaryString = output.cryptoSum
    }

    return `${summaryString} ${currencyCode}`
  }

  const getMinLimitSliderText = () => {
    const currencyCode = isFiatFlow ? fiatCode : cryptoCode
    const minLimitText = isFiatFlow ? output.minFiat : output.minCrypto

    return `${t('dashboard.collapseBody.minLimit')} ${minLimitText} ${currencyCode}`
  }

  const getMaxLimitSliderText = () => {
    const currencyCode = isFiatFlow ? fiatCode : cryptoCode
    const maxLimitText = isFiatFlow ? output.maxFiat : output.maxCrypto

    return `${t('dashboard.collapseBody.maxLimit')} ${maxLimitText} ${currencyCode}`
  }

  function getActionLabelText() {
    if (!isFiatFlow) {
      return typeText.input[1]
    }

    return t(
      isSelling ? `dashboard.collapseBody.sell.useFiat` : `dashboard.collapseBody.buy.useFiat`
    )
  }

  function getEquivalentValueString() {
    if (isFiatFlow) {
      return `= ${output.cryptoSum} ${cryptoCode}`
    }

    return `= ${output.fiatSum} ${fiatCode}`
  }

  const hasPaymentError = !!errorState?.payment && isSubmitted || isNoAvaivailblePaymentMethod

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorPrimaryHover: '#F3F3F8',
          },
        },
      }}
    >
      <MainWrapper>
        <FormStyled
          autoComplete="off"
          layout={'vertical'}
          disabled={isDisabledAdvertisement}
        >
          <FormWrapper>
            <CoinPriceWrapper>
              <BaseTypographyText $fontWeight={700}>
                {t('dashboard.collapseBody.coinPrice')}
              </BaseTypographyText>
              <GreenText $fontWeight={700}>
                {`${output.pricePerCoin} ${fiatCode}`}
              </GreenText>
            </CoinPriceWrapper>
            <DealFormWrapperStyled className='right-side-wrapper'>
              <FormItemWithCustomLabel
                name={INPUT_NAME}
                label={
                  <CustomLabelWrapper>
                    {/* Надпись "Хочу купить/Хочу продать/Хочу оплатить/Хочу получить" */}
                    <BaseTypographyText isDisabled={isDisabledAdvertisement}>
                      {getActionLabelText()}
                    </BaseTypographyText>
                    <BaseTypographyText isDisabled={isDisabledAdvertisement}>
                      {getEquivalentValueString()}
                    </BaseTypographyText>
                  </CustomLabelWrapper>
                }
                help={errorState?.buy && FormWithCustomLabelHelpText()}
              >
                <FormWithCustomLabelWrapper>
                  <DealQuantityInput 
                    useDealCreateStore={useDealCreateState}
                    isFiatFlow={isFiatFlow}
                    name={INPUT_NAME}
                    placeholder={getCustomInputPlaceholder()}
                    hasError={!!errorState?.buy}
                    currencyCode={isFiatFlow ? fiatCode : cryptoCode}
                    isDisabled={isDisabledAdvertisement}
                  />
                  <SwitchTwoElem
                    onClick={() => setIsFiatFlow(current => !current)}
                    isFirstActive={!isFiatFlow}
                    firstItem={cryptoCode}
                    secondItem={fiatCode}
                    isDisabled={isDisabledAdvertisement}
                  />
                </FormWithCustomLabelWrapper>
                <div>
                  <DealQuantitySlider 
                    useDealCreateStore={useDealCreateState}
                    isFiatFlow={isFiatFlow}
                    isDisabled={isDisabledAdvertisement}
                  />
                  <SliderTextWrapper>
                    <SliderText
                      isDisabled={isDisabledAdvertisement}
                    >
                      {getMinLimitSliderText()}
                    </SliderText>
                    <SliderText
                      isDisabled={isDisabledAdvertisement}
                    >
                      {getMaxLimitSliderText()}
                    </SliderText>
                  </SliderTextWrapper>
                </div>
              </FormItemWithCustomLabel>
              <FormItemStyled
                name={'paymentMethod'}
                help={PaymentMethodHelperText()}
                label={
                  <BaseTypographyText
                    isDisabled={isDisabledAdvertisement}
                  >
                    {t('dashboard.collapseBody.chooseMethod')}
                  </BaseTypographyText>
                }
              >
                <CustomizeSelector
                  status={
                    hasPaymentError ? 'error' : ''
                  }
                  placeholder={t('dashboard.collapseBody.paymentMethod')}
                  data={avaivaiblePaymentMethods}
                  disabled={isDisabledAdvertisement}
                  hasError={hasPaymentError}
                  eventName={'selectPaymentMethod'}
                  setValue={(data) => {
                    setSelectData(data)
                  }}
                />
              </FormItemStyled>
              {!isNoAvaivailblePaymentMethod && (
                <WrapperComisson>
                  {isDisabledAdvertisement ? (
                    <RedText>
                      {isMyAdvertisement
                        ? t('dashboard.collapseBody.errors.myAdd')
                        : isNotEnoughBalance
                          ? t('dashboard.collapseBody.errors.noMoneyForAdd')
                          : ''}
                    </RedText>
                  ) : (
                    <SummaryComissionWrapper>
                      <BlockInfo>
                        <Typography.Text>
                          {t('dashboard.collapseBody.summary')}
                        </Typography.Text>
                        <Typography.Text>{getSummary()}</Typography.Text>
                      </BlockInfo>
                      {!isSelling && (
                        <BlockInfo>
                          <SmallText>
                            {t('dashboard.collapseBody.commission')}
                          </SmallText>
                          <SmallText>{getComission()}</SmallText>
                        </BlockInfo>
                      )}
                    </SummaryComissionWrapper>
                  )}
                </WrapperComisson>
              )}
            </DealFormWrapperStyled>
          </FormWrapper>
        </FormStyled>
        <ButtonsWrapper>
          <Button
            width={'180px'}
            height={'40px'}
            disabled={isDisabledAdvertisement}
            onClick={handleCreate}
          >
            {isAuth ? typeText.text : t('p2p.login')}
          </Button>

          <Button
            width={'180px'}
            height={'40px'}
            onClick={handleCancel}
            type={ButtonsTypes.outlined}
          >
            {isAuth ? t('dashboard.collapseBody.cancel') : t('p2p.register')}
          </Button>
        </ButtonsWrapper>
      </MainWrapper>
    </ConfigProvider>
  )
}

const DealFormWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const FormStyled = styled(Form)`
  padding: 24px;
  width: 100%;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const CoinPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const BaseTypographyText = styled(Typography.Text)<{
  isDisabled?: boolean
  color?: string
  $fontWeight?: number
}>`
  color: ${({ isDisabled, color }) => (isDisabled ? '#474755' : color)};
  font-weight: ${(props) => props.$fontWeight};
`

const GreenText = styled(BaseTypographyText)`
  color: #58bf92;
`

const CustomLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const SliderText = styled(BaseTypographyText)`
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const FormItemWithCustomLabel = styled(Form.Item)`
  flex: 1;

  & label {
    width: 100%;
  }
`

const FormWithCustomLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`

const CustomizeSelector = styled(CustomSelect)<{ hasError: boolean }>`
  margin-bottom: ${({ hasError }) => (hasError ? '4px' : 'unset')};

  & .ant-select-selector {
    background-color: unset !important;
    border-color: ${({ hasError }) =>
      hasError ? '#F16063 !important' : 'unset'};
  }
`

const SliderTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`

const FormItemStyled = styled(Form.Item)`
  height: 100%;
  flex: 1.5;
`

const RedText = styled(Typography.Text)`
  color: #f16063;
  font-size: 14px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
  padding-right: 24px;
  padding-bottom: 24px;
`

const WrapperComisson = styled.div`
  border-top: 1px solid rgba(65, 61, 80, 0.5);
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`

const SummaryComissionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const BlockInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const SmallText = styled(BaseTypographyText)`
  font-size: 12px;
`
