import React, {useState} from 'react';
import {IMyDealsRow, SpotRangeFilterValue,} from '../../../shared/interfaces/spot';
import {RangeValue} from '../../../pages/p2p/myDeals/cards/myTrades.p';
import {ISortHistory} from '../ordersTable';
import dayjs from 'dayjs';
import {saveAsXLSX} from '../saveAsXLSX';
import {Col, DatePicker, Row, Tag} from '@pankod/refine-antd';
import Icon from '@ant-design/icons';
import {CaretIcon} from '../../../shared/components/ui/assets/svg/svgIcons';
import {Dropdown, Typography} from 'antd';
import {ChevronDown, DownLoadIcon} from '../../../shared/components/icons';
import VisibilityObserver from '../../../shared/hooks/on-visible/observer';
import {SpotMyDealTableRow} from './row';
import {useTranslation} from 'react-i18next';
import SpotTableLogin from '../spotTableLogin';
import {useCookiesCustom} from '../../../shared/hooks/useCookieCustom';

interface ISpotMyDealsTable {
    range: SpotRangeFilterValue;
    setRange: (data: SpotRangeFilterValue) => void;
    setSort: (data: ISortHistory) => void;
    rangeValue: RangeValue | undefined;
    handleRangeDate: (data: RangeValue) => void;
    handleNextPage: (vis: boolean) => void;
    myDeals: IMyDealsRow[];
    isAuth: boolean | undefined;
}

const SpotMyDealsTable: React.FC<ISpotMyDealsTable> = ({
                                                           range,
                                                           setRange,
                                                           setSort,
                                                           rangeValue,
                                                           handleRangeDate,
                                                           handleNextPage,
                                                           myDeals,
                                                       }) => {
    const {cookie} = useCookiesCustom();
    const {t} = useTranslation();
    const [openSortDate, setOpenSortDate] = useState(false);
    const [sortType, setSortType] = useState<'created_at' | 'updated_at'>(
        'created_at'
    );
    const [openCalendar, setOpenCalendar] = useState(false);
    const [openMarket, setOpenMarket] = useState(false);

    const handleChangeRange = (tag: typeof range) => {
        setRange(tag);
    };

    const handleChangeMarket = (value: string | number) => {
        setOpenMarket(false);
        if (value === 'all') {
            // setFilter(_.omit(filter, 'market'));
        } else {
            // setFilter({
            //   market: {
            //     field: 'market',
            //     operator: 'eq',
            //     value: !!value,
            //   },
            // });
        }
    };

    const handleChangeDateSort = (value: 'created_at' | 'updated_at') => {
        setOpenSortDate(false);
        setSortType(value);
        setSort({
            date: {
                field: value,
                order: 'desc',
            },
        });
    };

    const translateSortType = (value: string) => {
        if (value === 'created_at') return t('myDeals.table.create');
        else return t('myDeals.table.update');
    };

    const handleSave = () => {
        saveAsXLSX(
            Object.values(myDeals)
                .map((i: any, index) => {
                    const tmp = [
                        {
                            // TODO: Check
                            created_at:
                                i.created_at === t('myDeals.dateAndTime')
                                    ? t('myDeals.dateAndTime')
                                    : dayjs(new Date(i.created_at)).format('DD.MM.YYYY hh:mm'),
                            id: i.id,
                            market: i.market
                                ? t('myDeals.table.market')
                                : t('myDeals.table.limit'),
                            pair: 'BTC / USDT',
                            percent: i.percent,
                            price: i.price,
                            sum: i.sum,
                            type: i.type,
                            balance: i.balance,
                            deposit: i.deposit,
                        },
                    ];
                    if (index === 0) {
                        tmp.unshift({
                            created_at: t('myDeals.table.createdAt'),
                            id: t('myDeals.table.orderNumber'),
                            market: t('myDeals.table.view'),
                            pair: t('myDeals.table.pair'),
                            percent: t('myDeals.table.execution'),
                            price: t('myDeals.table.price'),
                            sum: t('myDeals.table.quantity'),
                            type: t('myDeals.table.type'),
                            balance: t('myDeals.table.total'),
                            deposit: t('myDeals.table.deposit'),
                        });
                    }
                    return tmp;
                })
                .flat(),
            `${t('myDeals.table.tableOnTheHistoryOfOrdersFrom')} ${dayjs(
                new Date()
            ).format('DD.MM.YYYY')}`
        );
    };

    return (
        <div
            className={'flex-column gap-16'}
            style={{
                height: '100%',
                position: 'relative',
            }}
        >
            <div className={'flex-row justify-content-space-between'}>
                <div className={'flex-row flex-start gap-24 align-items-center'}>
                    <div className={'flex-row gap-16 history-orders-tags'}>
                        <Tag.CheckableTag
                            checked={'day' === range}
                            onChange={() => handleChangeRange('day')}
                        >
                            {t('myDeals.table.periods.day')}
                        </Tag.CheckableTag>
                        <Tag.CheckableTag
                            checked={'week' === range}
                            onChange={() => handleChangeRange('week')}
                        >
                            {t('myDeals.table.periods.week')}
                        </Tag.CheckableTag>
                        <Tag.CheckableTag
                            checked={'month' === range}
                            onChange={() => handleChangeRange('month')}
                        >
                            {t('myDeals.table.periods.month')}
                        </Tag.CheckableTag>
                        <Tag.CheckableTag
                            checked={'3month' === range}
                            onChange={() => handleChangeRange('3month')}
                        >
                            {t('myDeals.table.periods.3month')}
                        </Tag.CheckableTag>
                        <Tag.CheckableTag
                            checked={'6month' === range}
                            onChange={() => handleChangeRange('6month')}
                        >
                            {t('myDeals.table.periods.6month')}
                        </Tag.CheckableTag>
                    </div>
                    <div className={'container-range'}>
                        <DatePicker.RangePicker
                            className={`cursor-pointer range-orders-history ${
                                (rangeValue?.length ?? 0) > 0 ? 'active-range' : ''
                            }`}
                            suffixIcon={<Icon component={() => CaretIcon()}/>}
                            separator={''}
                            onOpenChange={setOpenCalendar}
                            open={openCalendar}
                            value={rangeValue}
                            onCalendarChange={handleRangeDate}
                        />
                        <div
                            className={'absolute-range'}
                            onClick={() => setOpenCalendar((prevState) => !prevState)}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M3.5 0C3.77614 0 4 0.223858 4 0.5V1H12V0.5C12 0.223858 12.2239 0 12.5 0C12.7761 0 13 0.223858 13 0.5V1H14C15.1046 1 16 1.89543 16 3V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V3C0 1.89543 0.895431 1 2 1H3V0.5C3 0.223858 3.22386 0 3.5 0ZM2 2C1.44772 2 1 2.44772 1 3V4H15V3C15 2.44772 14.5523 2 14 2H2ZM15 5H1V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V5Z"
                                        fill="#767687"
                                    />
                                </svg>
                                <Typography.Text style={{color: '#767687'}}>
                                    {t('myDeals.table.selectAPeriod')}
                                </Typography.Text>
                            </div>
                        </div>
                    </div>
                    <Dropdown
                        onOpenChange={setOpenSortDate}
                        menu={{
                            items: [
                                {
                                    key: 'created_at',
                                    label: t('myDeals.table.sortByDate.byCreationTime'),
                                    onClick: (info) =>
                                        handleChangeDateSort(
                                            info.key as 'created_at' | 'updated_at'
                                        ),
                                },
                                {
                                    key: 'updated_at',
                                    label: t('myDeals.table.sortByDate.byUpdateTime'),
                                    onClick: (info) =>
                                        handleChangeDateSort(
                                            info.key as 'created_at' | 'updated_at'
                                        ),
                                },
                            ],
                            selectable: true,
                            defaultSelectedKeys: ['created_at'],
                        }}
                    >
                        <div
                            className={
                                'flex-row align-items-center gap-8 spot-sort-date-history'
                            }
                        >
                            <Typography.Text style={{color: '#767687'}}>
                                {t('myDeals.table.sortByTime')} {translateSortType(sortType)}
                            </Typography.Text>
                            <div
                                className={'cursor-pointer flex align-items-center'}
                                style={{rotate: openSortDate ? '180deg' : '0deg'}}
                            >
                                {ChevronDown('#767687', 12)}
                            </div>
                        </div>
                    </Dropdown>
                </div>
                <div hidden>
                    <div
                        className={'flex-row align-items-center gap-8 cursor-pointer'}
                        onClick={handleSave}
                    >
                        {DownLoadIcon('#767687', 16)}
                        <Typography.Text style={{color: '#767687'}}>
                            {t('myDeals.table.exportingATable')}
                        </Typography.Text>
                    </div>
                </div>
            </div>
            <div
                style={{
                    border: '1px solid #413D5080',
                    padding: '16px 20px',
                    height: myDeals.length === 0 ? '100%' : 'unset',
                }}
            >
                <Row justify={'space-between'} style={{marginBottom: '16px'}}>
                    <Col span={2}>
                        <Typography.Text>{t('myDeals.table.dateAndTime')}</Typography.Text>
                    </Col>
                    <Col span={2}>
                        <Typography.Text>{t('myDeals.table.pair')}</Typography.Text>
                    </Col>
                    <Col span={2}>
                        <Dropdown
                            onOpenChange={setOpenMarket}
                            menu={{
                                items: [
                                    {
                                        key: 'all',
                                        label: t('myDeals.table.openMarket.all'),
                                        onClick: (info) => handleChangeMarket(info.key),
                                    },
                                    {
                                        key: 1,
                                        label: t('myDeals.table.openMarket.buy'),
                                        onClick: (info) => handleChangeMarket(info.key),
                                    },
                                    {
                                        key: 0,
                                        label: t('myDeals.table.openMarket.sell'),
                                        onClick: (info) => handleChangeMarket(info.key),
                                    },
                                ],
                                selectable: true,
                                defaultSelectedKeys: ['all'],
                            }}
                        >
                            <div className={'flex-row align-items-center gap-8'}>
                                <Typography.Link>{t('myDeals.table.type')}</Typography.Link>
                                <div
                                    className={'cursor-pointer flex align-items-center'}
                                    style={{rotate: openMarket ? '180deg' : '0deg'}}
                                >
                                    {ChevronDown('#FBFBFF', 12)}
                                </div>
                            </div>
                        </Dropdown>
                    </Col>
                    <Col span={2}>
                        <Typography.Text>{t('myDeals.table.price')}</Typography.Text>
                    </Col>
                    <Col span={2}>
                        <Typography.Text>{t('myDeals.table.quantity')}</Typography.Text>
                    </Col>
                    <Col span={2}>
                        <Typography.Text>{t('myDeals.table.commission')}</Typography.Text>
                    </Col>
                    <Col span={2}>
                        <Typography.Text>
                            {t('myDeals.table.transactionAmount')}
                        </Typography.Text>
                    </Col>
                    <Col span={2}>
                        <Typography.Text>{t('myDeals.table.amountInUSD')}</Typography.Text>
                    </Col>
                </Row>
                {!!cookie['token'] && myDeals.length === 0 ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                alignContent: 'center',
                                justifyContent: 'center',
                                justifyItems: 'center',
                            }}
                        >
                            {/*<Typography.Text style={{ color: '#767687' }}>*/}
                            {/*  <EmptyComponent text={t('myDeals.table.youHaveNoDeals')} />*/}
                            {/*</Typography.Text>*/}
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            overflow: 'overlay',
                            marginLeft: -16,
                            marginRight: -16,
                        }}
                    >
                        {!!cookie['token'] ? (
                            myDeals.map((deal, index) =>
                                index === myDeals.length - 1 ? (
                                    <VisibilityObserver
                                        key={deal.id}
                                        onChangeVisibility={handleNextPage}
                                        options={{rootMargin: '100px'}}
                                    >
                                        <SpotMyDealTableRow row={deal}/>
                                    </VisibilityObserver>
                                ) : (
                                    <SpotMyDealTableRow key={deal.id} row={deal}/>
                                )
                            )
                        ) : (
                            <div
                                style={{
                                    padding: '29vh 0.25rem',
                                }}
                            >
                                <SpotTableLogin/>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export {SpotMyDealsTable};
