import React from 'react';
import { Skeleton, TableColumnType, TableProps } from '@pankod/refine-antd';
import SmartTable from '../../shared/components/ui/table/smartTable';
import { IGetListData } from './myOrders.p';
import { useNavigate } from '@pankod/refine-react-router-v6';

interface IMyOrdersTable {
  isLoading: boolean;
  tableProps: TableProps<any>;
  columns: TableColumnType<any>[];
  onRowClick?: (id: string) => void;
  fetchData: () => void;
  dataSource: IGetListData[];
}

const MyOrdersTable: React.FC<IMyOrdersTable> = ({
  isLoading,
  columns,
  tableProps,
  onRowClick,
  fetchData,
  dataSource,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      {isLoading ? (
        <>
          <div className={'flex-column gap-4'}>
            <Skeleton.Input
              active={isLoading}
              style={{
                width: '100%',
                height: 64,
                borderRadius: '16px 16px 0px 0px',
              }}
            />
            <Skeleton.Input
              active={isLoading}
              style={{
                width: '100%',
                height: 56,
              }}
            />
            <Skeleton.Input
              active={isLoading}
              style={{
                width: '100%',
                height: 56,
              }}
            />
            <Skeleton.Input
              active={isLoading}
              style={{
                width: '100%',
                height: 56,
              }}
            />
            <Skeleton.Input
              active={isLoading}
              style={{
                width: '100%',
                height: 56,
              }}
            />
            <Skeleton.Input
              active={isLoading}
              style={{
                width: '100%',
                height: 56,
              }}
            />
            <Skeleton.Input
              active={isLoading}
              style={{
                width: '100%',
                height: 56,
              }}
            />
            <Skeleton.Input
              active={isLoading}
              style={{
                width: '100%',
                height: 56,
                borderRadius: '0px 0px 16px 16px',
              }}
            />
          </div>
        </>
      ) : (
        <>
          <SmartTable
            {...tableProps}
            dataSource={dataSource}
            onFetch={fetchData}
            infinity
            bordered={false}
            pagination={false}
            columns={columns}
            scroll={{ y: 514 }}
            style={{
              borderCollapse: 'collapse',
              borderRadius: 16,
            }}
            onRow={(record) => {
              return {
                style: {
                  cursor: 'pointer',
                },
                onClick: () => {
                  if (onRowClick) {
                    onRowClick(record.id);
                  }
                },
                onMouseDown: (event) => {
                  if (event.button === 1 && onRowClick) {
                    navigate(`/exchange-outputOrder/${record.id}`);
                  }
                },
              };
            }}
          />
        </>
      )}
    </div>
  );
};

export default MyOrdersTable;
