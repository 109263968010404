import BN from 'bignumber.js'

import { TWalletState } from 'useWalletState'
import { IDealCreateState, TAdvType, TDealCreateUpdate } from '../interface'

export const getAmountByCurrency = (
  balances: TWalletState['balances'],
  currency: string
) => {

  const foundBalance = balances.find((balance) => balance.name === currency)

  return foundBalance ? foundBalance.balance.amount : 0
}

export const composeDealCreateState = (updateData: Partial<TDealCreateUpdate>, currentState: IDealCreateState): IDealCreateState => {
  const newData = normalizeDealCreateData(updateData)
  // делаем compose т.к. впоследствии проще работать с полным state
  const newState = {
    ...currentState,
    ...newData
  }

  // апдейт фиат прайс, если выходит за fiat ranges
  {
    const { minFiat, maxFiat, fiatSum: currentFiatSum } = newState

    if (
      minFiat.comparedTo(currentFiatSum) !== -1
    ) {
      newState.fiatSum = minFiat
    } else if (maxFiat.comparedTo(currentFiatSum) !== 1) {
      newState.fiatSum = maxFiat
    }
  }

  const pricePerCoinChanged = !newState.pricePerCoin.isEqualTo(currentState.pricePerCoin)
  // апдейт crypto ranges, если изменились fiat ranges или pricePerCoin
  {
    const { minFiat, maxFiat, pricePerCoin } = newState
    
    if (!minFiat.isEqualTo(currentState.minFiat) || pricePerCoinChanged) {
      newState.minCrypto = minFiat.div(pricePerCoin)
    }
  
    if (!maxFiat.isEqualTo(currentState.maxFiat) || pricePerCoinChanged) {
      newState.maxCrypto = maxFiat.div(pricePerCoin)
    }
  }

  // апдейт cryptoSum, если изменился fiatSum или pricePerCoin
  {
    const { fiatSum: currentFiatSum, minCrypto, maxCrypto, pricePerCoin } = newState

    if (!currentFiatSum.isEqualTo(currentState.fiatSum) || pricePerCoinChanged) {
      let newCryptoSum = currentFiatSum.div(pricePerCoin)

      if (minCrypto.comparedTo(newCryptoSum) !== -1) {
        newCryptoSum = minCrypto
      } else if (maxCrypto.comparedTo(newCryptoSum) !== 1) {
        newCryptoSum = maxCrypto
      }

      newState.cryptoSum = newCryptoSum
    }
  }

  return newState
}

export const normalizeDealCreateData = (updateData: Partial<TDealCreateUpdate>): Partial<IDealCreateState> => {
  const newState: Partial<IDealCreateState> = {}

  let updateKey: keyof TDealCreateUpdate
  for (updateKey in updateData) {
    const value = updateData[updateKey]

    if (!value) {
      continue
    }

    if (updateKey === 'advType') {
      newState[updateKey] = value as TAdvType
    } else {
      newState[updateKey] = BN(value)
    }
  }

  return newState
}
