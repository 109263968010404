import { FC, memo, useCallback, useEffect, useState } from 'react'
import BN from 'bignumber.js'

import { AdditionalPriceRange } from '../model/types'
import { Slider } from '@pankod/refine-antd'
import { FIAT_DP } from '../../../lib/constants'

const STEPS_COUNT = 100

interface IProps {
  adPriceValue: number
  changeAdPriceValue(value: number): void
  isDisabled?: boolean
  priceRange: AdditionalPriceRange
}

const AdPriceSlider: FC<IProps> = memo(function AdPriceSlider({
  adPriceValue,
  isDisabled = false,
  priceRange,
  changeAdPriceValue,
}) {
  const [currentStep, setCurrentStep] = useState(0)

  const onSliderStepChange = useCallback(
    (value: number) => {
      let nextAdPrice = 0

      // is extremum
      if (value === 0 || value === STEPS_COUNT) {
        nextAdPrice = (value === 0 ? priceRange.min : priceRange.max).toNumber()
      } else {
        const proportionalValue = priceRange.step
          .times(value)
          .plus(priceRange.min)
          .dp(FIAT_DP, BN.ROUND_HALF_DOWN)
          .toNumber()

        nextAdPrice = Math.max(proportionalValue, priceRange.min.toNumber())
      }

      changeAdPriceValue(nextAdPrice)
    },
    [priceRange],
  )

  useEffect(() => {
    const priceValueBN = BN(adPriceValue || 0)
    const stepValue = priceValueBN.minus(priceRange.min).div(priceRange.step)
    const nextStep = BN.max(0, BN.min(STEPS_COUNT, stepValue))
      .dp(0, BN.ROUND_DOWN)
      .toNumber()

    setCurrentStep(nextStep)
  }, [priceRange, adPriceValue])

  return (
    <Slider
      step={1}
      min={0}
      max={100}
      value={currentStep}
      onChange={onSliderStepChange}
      disabled={isDisabled}
      tooltip={{ formatter: null }}
    />
  )
})

export default AdPriceSlider
