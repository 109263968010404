import { Input } from "@pankod/refine-antd"
import { ChangeEventHandler, FC, memo, useCallback, useEffect, useRef, useState } from "react"
import BN from "bignumber.js"
import { styled } from "styled-components"

import { TDealStore } from "./hooks/useDealCreateState"
import { useShallowState } from "./hooks/useShallowState"
import { CRYPTO_DP, CRYPTO_ROUNDING, FIAT_DP, FIAT_ROUNDING } from "./lib/constants"

interface IProps {
  useDealCreateStore: TDealStore
  hasError: boolean
  name: string
  placeholder: string
  isFiatFlow: boolean
  isDisabled: boolean
  currencyCode: string
}

export const DealQuantityInput: FC<IProps> = memo((props) => {
  const { 
    useDealCreateStore,
    name,
    placeholder,
    hasError, 
    isFiatFlow, 
    isDisabled, 
    currencyCode
  } = props

  const [inputString, setInputString] = useState('')

  const isFocusedRef = useRef(false)

  const { fiatSum, cryptoSum, setFiatSum, setCryptoSum } = useDealCreateStore(useShallowState(state => {
    return {
      fiatSum: state.fiatSum,
      cryptoSum: state.cryptoSum,
      setFiatSum: state.actions.setFiatSum,
      setCryptoSum: state.actions.setCryptoSum
    }
  }))

  const spanColor = isDisabled ? '#767687' : '#FBFBFF'

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    const input = event.target.value
    const regex = /(?:\d*\.)?\d+/
    const inputValue = input.replace(/[^\d\.]/g, '').match(regex)
    const stringValue = inputValue?.[0] || 0
    const bnValue = BN(stringValue)

    if (bnValue.isFinite()) {
      isFiatFlow ? setFiatSum(bnValue) : setCryptoSum(bnValue)

      setInputString(input)
    }
  }, [setFiatSum, setCryptoSum, isFiatFlow])

  const synchronizeString = () => {
    const stringValue = isFiatFlow 
      ? fiatSum.toFixed(FIAT_DP, FIAT_ROUNDING) 
      : cryptoSum.toFixed(CRYPTO_DP, CRYPTO_ROUNDING)

    setInputString(stringValue)
  }

  useEffect(() => {
    if (isFocusedRef.current) {
      return
    }

    synchronizeString()
  }, [fiatSum, cryptoSum])

  useEffect(synchronizeString, [isFiatFlow])

  const onFocus = () => {
    isFocusedRef.current = true
  }

  const onBlur = () => {
    isFocusedRef.current = false

    synchronizeString()
  }

  return (
   <CustomInput
      type={'number'}
      status={hasError ? 'error' : ''}
      aria-label={name}
      onChange={handleInputChange}
      suffix={
        <SpanStyled $color={spanColor}>
          {currencyCode}
        </SpanStyled>
      }
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      value={inputString}
      disabled={isDisabled}
    />
  )
})

const CustomInput = styled(Input)`
  margin-top: 0;
  background-color: unset;
`

const SpanStyled = styled.span<{ $color?: string }>`
  color: ${(props) => props.$color};
`