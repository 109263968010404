import BN from "bignumber.js"

export const FIAT_DP = 2
export const CRYPTO_DP = 8  // TODO: переделать на dp текущей монеты/стандарта
export const FIAT_ROUNDING = BN.ROUND_HALF_EVEN  // банковское округление
export const CRYPTO_ROUNDING = BN.ROUND_DOWN  // округляем вниз для страховки от абьюза (условно 0.05 округлятся до 0.01)

export const ADV_TYPE = {
  BUY: 'buy',
  SELL: 'sell',
} as const