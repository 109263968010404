import { useRef } from "react"
import { shallow } from "zustand/shallow"

export const useShallowValue = <T extends Record<string, unknown>>(value: T) => {
  const valueRef = useRef<T>()

  const areEqual = shallow(value, valueRef.current)

  if (!areEqual) {
    valueRef.current = value
  }
  
  return valueRef.current
}