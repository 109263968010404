import React from 'react';
import { Dropdown, DropdownProps } from 'antd';

interface IDropdown extends DropdownProps {}

const Index: React.FC<IDropdown> = ({ ...props }) => {
  return (
    <>
      <Dropdown
        overlayStyle={{
          paddingTop: 8,
          paddingBottom: 8,
          background: '#2B2B36',
          borderRadius: 4,
          border: '1px solid #474755',
          ...props.overlayStyle,
        }}
        {...props}
      />
    </>
  );
};

export default Index;
