import BigNumber from "bignumber.js"

export enum ERROR_TYPES {
  CHOOSE_REGION = 'CHOOSE_REGION',
  WRONG_MAX_LIMIT = 'WRONG_MAX_LIMIT',
  WRONG_MIN_LIMIT = 'WRONG_MIN_LIMIT',
  WRONG_LIMITS = 'WRONG_LIMITS',
  WRONG_FIAT = 'WRONG_FIAT',
  WRONG_TOTAL_SUM = 'WRONG_TOTAL_SUM',
  MIN_LIMIT_LT_5 = 'MIN_LIMIT_LT_5',
  MAX_LIMIT_GT_TOTAL = 'MAX_LIMIT_GT_TOTAL',
  NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE',
  WRONG_PAYMENT_METHODS_NUMBER = 'WRONG_PAYMENT_METHODS_NUMBER',
}

export const FIAT_DP = 2
export const FIAT_ROUND = BigNumber.ROUND_HALF_EVEN
export const CRYPTO_DP = 8
export const CRYPTO_ROUND = BigNumber.ROUND_DOWN