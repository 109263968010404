import React from 'react';
import { DatePicker, Skeleton } from '@pankod/refine-antd';
import Dropdown from '../../shared/components/ui/dropdown';
import { Typography } from 'antd';
import Icon from '@ant-design/icons';
import {
  CaretIcon,
  DashCircle,
  DividerIcon,
} from '../../shared/components/ui/assets/svg/svgIcons';
import dayjs from 'dayjs';
import { MenuProps } from 'antd/es/menu/menu';
import { RangePickerProps } from 'antd/es/date-picker';
import { LogicalFilter } from '@pankod/refine-core';
import { RangeValue } from './myOrders.p';
import { createDateAsUTC } from '../../shared/helpers/convertTime';

const dateFormat = 'YYYY-MM-DD';

interface IMyOrdersFilters {
  isLoading: boolean;
  inputCoinItemsMenu: MenuProps;
  outputCoinItemsMenu: MenuProps;
  statusItemsMenu: MenuProps;
  currentInputCoin: string | JSX.Element | undefined | null;
  currentOutputCoin: string | JSX.Element | undefined | null;
  currentStatus: string | JSX.Element | undefined | null;
  startDate: Date;
  endDate: Date;
  setFilter: (filter: LogicalFilter) => void;
  dates: RangeValue;
  value: RangeValue;
  setValue: (arg: RangeValue) => void;
  setDates: (arg: RangeValue) => void;
}

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days after today
  return current && current > dayjs().endOf('day');
};

const MyOrdersFilters: React.FC<IMyOrdersFilters> = ({
  isLoading,
  inputCoinItemsMenu,
  outputCoinItemsMenu,
  statusItemsMenu,
  currentInputCoin,
  currentOutputCoin,
  currentStatus,
  startDate,
  endDate,
  setFilter,
  dates,
  value,
  setDates,
  setValue,
}) => {
  return (
    <>
      <Skeleton
        active={isLoading}
        loading={isLoading}
        title={false}
        className={'custom-skeleton-elements'}
        paragraph={{
          rows: 1,
          width: '100%',
        }}
      >
        <div className={'flex-row gap-16 justify-content-space-between'}>
          <div className={'flex-row gap-8 align-items-center'}>
            <Dropdown
              className={'cursor-pointer'}
              menu={inputCoinItemsMenu}
              trigger={['click']}
            >
              <div
                className={
                  'flex-row align-items-center justify-content-space-between'
                }
                style={{
                  background: '#2B2B36',
                  borderRadius: 8,
                  width: 270,
                  height: 40,
                  padding: '0 16px',
                  border: '1px solid #474755',
                }}
              >
                <Typography.Text
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '120%',
                    color: 'white',
                  }}
                >
                  {`${currentInputCoin}`}
                </Typography.Text>
                <div
                  style={{
                    height: 16,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 8,
                  }}
                >
                  {!!inputCoinItemsMenu?.items &&
                    inputCoinItemsMenu?.items[0]?.key !== 'all' && (
                      <Icon
                        component={() => DashCircle()}
                        onClick={() =>
                          setFilter({
                            field: 'input_currency',
                            operator: 'contains',
                            value: '',
                          })
                        }
                      />
                    )}
                  <Icon component={() => CaretIcon()} />
                </div>
              </div>
            </Dropdown>
            <Icon component={DividerIcon} />
            <Dropdown
              className={'cursor-pointer'}
              menu={outputCoinItemsMenu}
              trigger={['click']}
            >
              <div
                className={
                  'flex-row align-items-center justify-content-space-between'
                }
                style={{
                  background: '#2B2B36',
                  borderRadius: 8,
                  width: 270,
                  height: 40,
                  padding: '0 16px',
                  border: '1px solid #474755',
                }}
              >
                <Typography.Text
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '120%',
                    color: 'white',
                  }}
                >
                  {currentOutputCoin}
                </Typography.Text>
                <div
                  style={{
                    height: 16,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 8,
                  }}
                >
                  {!!outputCoinItemsMenu?.items &&
                    outputCoinItemsMenu?.items[0]?.key !== 'all' && (
                      <Icon
                        component={() => DashCircle()}
                        onClick={() =>
                          setFilter({
                            field: 'output_currency',
                            operator: 'contains',
                            value: '',
                          })
                        }
                      />
                    )}
                  <Icon component={() => CaretIcon()} />
                </div>
              </div>
            </Dropdown>
          </div>
          <Dropdown
            className={'cursor-pointer'}
            menu={statusItemsMenu}
            trigger={['click']}
          >
            <div
              className={
                'flex-row align-items-center justify-content-space-between'
              }
              style={{
                background: '#2B2B36',
                borderRadius: 8,
                width: 270,
                height: 40,
                padding: '0 16px',
                border: '1px solid #474755',
              }}
            >
              <Typography.Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: '120%',
                  color: 'white',
                }}
              >
                {currentStatus}
              </Typography.Text>
              <div
                style={{
                  height: 16,
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 8,
                }}
              >
                {!!statusItemsMenu?.items &&
                  statusItemsMenu?.items[0]?.key !== 'all' && (
                    <Icon
                      component={() => DashCircle()}
                      onClick={() =>
                        setFilter({
                          field: 'status',
                          operator: 'contains',
                          value: '',
                        })
                      }
                    />
                  )}
                <Icon component={() => CaretIcon()} />
              </div>
            </div>
          </Dropdown>

          <DatePicker.RangePicker
            className={'cursor-pointer'}
            defaultValue={[
              dayjs(startDate.toISOString(), dateFormat),
              dayjs(endDate.toISOString(), dateFormat),
            ]}
            suffixIcon={<Icon component={() => CaretIcon()} />}
            clearIcon={<Icon component={() => DashCircle()} />}
            separator={<>-</>}
            disabledDate={disabledDate}
            value={value || dates || undefined}
            onCalendarChange={(val) => {
              setValue(val);
              if (val === null) {
                setFilter({
                  field: 'created_at',
                  operator: 'contains',
                  value: '',
                });
              } else {
                setFilter({
                  field: 'created_at',
                  operator: 'between',
                  value: [
                    createDateAsUTC(dayjs(val[0]).toDate()),
                    createDateAsUTC(dayjs(val[1]).toDate()),
                  ],
                });
              }
            }}
            onChange={(val) => {
              setValue(val);
              if (val === null) {
                setFilter({
                  field: 'created_at',
                  operator: 'contains',
                  value: '',
                });
              } else {
                setFilter({
                  field: 'created_at',
                  operator: 'between',
                  value: [
                    createDateAsUTC(dayjs(val[0]).toDate()),
                    createDateAsUTC(dayjs(val[1]).toDate()),
                  ],
                });
              }
            }}
          />
        </div>
      </Skeleton>
    </>
  );
};

export default MyOrdersFilters;
