import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {IP2POrderUser} from 'pages/p2p/myDeals/cards/myTrades.p';
import {CenterBox, RoundShape, SmallText} from './card';
import {isNumber} from "lodash";
import { getRatioPercentageString } from 'shared/helpers/getRatioPercentageString';

type Props = {
  user: IP2POrderUser;
}

const UserTooltip: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  console.log('props.user', props)
  const {total_orders_amount, success_orders_amount, canceled_orders_amount, unique_agents_amount} = props?.user;

  const completedDealsPercentage = getRatioPercentageString(total_orders_amount, success_orders_amount)

  return (
    <div className={'flex-column'} style={{gap: 12}}>
      <div className={'flex-column'} style={{gap: 8,}}>
        <GapCenterBox className={'flex-row'} gap={8}>
          <LargeRoundShape color='linear-gradient(76deg, #6320E5 6.84%, #7B59FD 99.53%)'/>
          <SmallText>{t("myDealsCard.tooltip.totalAmount") + " " + isNumber(total_orders_amount) ? total_orders_amount : 0}</SmallText>
        </GapCenterBox>
        <CenterBox className={'flex-row'} style={{justifyContent: 'center'}}>
          <SmallRoundShape color='#58BF92'/>
          <SmallText>{t('myDealsCard.tooltip.success') + " " + isNumber(success_orders_amount) ? success_orders_amount : 0}</SmallText>
          <SmallText style={{margin: '0 4px'}}>/</SmallText>
          <SmallRoundShape color='#F16063'/>
          <SmallText>{t('myDealsCard.tooltip.canceled') + " " + isNumber(canceled_orders_amount) ? canceled_orders_amount : 0}</SmallText>
        </CenterBox>
      </div>
      <GapCenterBox className={'flex-row'} gap={8}>
        <LargeRoundShape color='linear-gradient(76deg, #6320E5 6.84%, #7B59FD 99.53%)'/>
        <SmallText>{t("myDealsCard.tooltip.percentage")}{' '}{completedDealsPercentage}</SmallText>
      </GapCenterBox>
      <GapCenterBox className={'flex-row'} gap={8}>
        <LargeRoundShape color='linear-gradient(76deg, #6320E5 6.84%, #7B59FD 99.53%)'/>
        <SmallText>{t("myDealsCard.tooltip.agentAmount") + " " + isNumber(unique_agents_amount) ? unique_agents_amount : 0}</SmallText>
      </GapCenterBox>
    </div>
  );
};

const SmallRoundShape = styled(RoundShape)`
  height: 4px;
  width: 4px;
`

const GapCenterBox = styled(CenterBox)<{ gap: number }>`
  gap: ${(props) => props.gap}px;
`

const LargeRoundShape = styled(RoundShape)`
  height: 8px;
  width: 8px;
`

export default UserTooltip;
