import { useState } from "react";
import { IPaymentInfo } from "../interface";
import { HttpError, useList } from "@pankod/refine-core";

export const useUserPaymentMethods = (uid: number): [IPaymentInfo[] | undefined, typeof fetchUserPaymentMethods] => {
  const [userPaymentMethods, setPaymentData] = useState<IPaymentInfo[]>();

  // запрашиваем для объявлений buy
  // TODO: перенести куда-нибудь на уровень выше и сделать singleton'ом на весь список
  const { refetch: fetchUserPaymentMethods } = useList<IPaymentInfo, HttpError>({
    dataProviderName: 'p2p',
    resource: 'payment',
    config: {
      filters: [
        {
          field: 'account_id',
          operator: 'eq',
          value: uid,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 100,
      },
    },
    queryOptions: {
      enabled: false,
      onSuccess: (res) => {
        setPaymentData(res.data);
      },
      retry: false,
    },
  });

  return [ userPaymentMethods, fetchUserPaymentMethods ]
}