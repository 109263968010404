import styled from 'styled-components'

import { PaymentTypeEnum } from 'features/p2p/createOrder/types'
import { IPaymentsType } from '../interface'

type IIconProps = {
  payment_type: PaymentTypeEnum
  name: string
  iconsData?: IPaymentsType[]
}

export const ImgIcon = ({ payment_type, name, iconsData }: IIconProps) => (
  <StyledImg
    alt={'paymentIcon'}
    src={`data:image/svg+xml;base64,${
      iconsData
        ?.find((type) => type.payment_type === payment_type)
        ?.items?.find((icon) => icon.name === name)?.icon
    }`}
  />
)

const StyledImg = styled.img`
  height: 16px;
`