import {ConfigProvider, Divider, Form, FormInstance, Input, Tag, Typography,} from '@pankod/refine-antd';
import React, {useState} from 'react';
import {styled} from 'styled-components';
import Button from '../../../shared/components/ui/button';
import {useTranslate} from '@pankod/refine-core';
import {IGetListAdData} from './interface';
import {DefaultOptionType} from 'rc-select/lib/Select';
import {CloseIcon} from 'shared/components/ui/assets/svg/close';
import {ArrowThin, CollapseArrow, ProfileCircle,} from 'shared/components/icons';

type AdExpandedProps = {
  isOpen: boolean;
  typeText: {
    text: string;
    input: string[];
    placeholder: string;
    sellMin: number;
    sellMax: number;
  };
  dataAd: IGetListAdData;
  item: IGetListAdData;
  form: FormInstance;
  error: {
    buy?: string;
    payment?: string;
  };
  handleChangeBuy: (e: React.ChangeEvent<HTMLInputElement>) => void;
  buyWatch: any;
  methodArray: (icon?: boolean, method?: boolean) => DefaultOptionType[] | undefined;
  isUpdate: boolean;
  handleUpdate: () => Promise<void>;
  handleCreate: () => Promise<void>;
  close: React.Dispatch<boolean>;
};

const FColDivider = styled(Divider)`
  margin: 0;
  background-color: #474755;
  width: 100%;
  left: 0;
`;

export const AdExpanded = ({
                             isOpen,
                             typeText,
                             dataAd,
                             item,
                             form,
                             error,
                             handleChangeBuy,
                             buyWatch,
                             methodArray,
                             isUpdate,
                             handleUpdate,
                             handleCreate,
                             close,
                           }: AdExpandedProps) => {
  const translate = useTranslate();

  const [methodIsOpen, setMethodIsOpen] = useState(false);
  const [actionType, _] = useState(item.type === 'sell' ? 'buy' : 'sell');
  const paymentMethodsList = methodArray()

  return (
    <DrawerStyled
      $height={isOpen ? '100vh' : '0'}
      $padding={isOpen ? '15px' : '0'}
      $overflow={isOpen ? 'scroll' : 'hidden'}
    >
      <div>
        <TitleWrapper>
          <Typography.Title>
            {translate(`announcements.types.${actionType}`)} {item.ccy}
          </Typography.Title>
          <IconWrapper onClick={() => close(false)}>
            <CloseIcon/>
          </IconWrapper>
        </TitleWrapper>

        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorPrimaryHover: '#F3F3F8',
                controlHeight: 56,
                fontSize: 16
              },
            },
          }}
        >
          <Form form={form} name="basic" autoComplete="off" layout={'vertical'} className='offer-buy-form'>
            <DrawerInput>
              <Form.Item
                name={'buy'}
                style={{flex: 1}}
                help={
                  !!error.buy && (
                    <span className={'helpInput'}>{error.buy}</span>
                  )
                }
                label={
                  actionType === 'buy'
                    ? translate('dashboard.collapseBody.buy.last')
                    : translate('dashboard.collapseBody.sell.last')
                }
              >
                <InputStyled
                  status={!!error.buy ? 'error' : ''}
                  aria-label={'buy'}
                  onChange={handleChangeBuy}
                  suffix={
                    <SpanStyled $color={!!buyWatch ? '#FBFBFF' : '#767687'}>
                      {dataAd.fiat}
                    </SpanStyled>
                  }
                  placeholder={`${dataAd.min_sum_limit} - ${dataAd.max_sum_limit}`}
                />
              </Form.Item>
              <Form.Item
                name={'sell'}
                style={{flex: 1}}
                label={
                  actionType === 'buy'
                    ? translate('dashboard.collapseBody.buy.first')
                    : translate('dashboard.collapseBody.sell.first')
                }
              >
                <InputStyled
                  aria-label={'sell'}
                  onChange={handleChangeBuy}
                  suffix={
                    <SpanStyled $color={!!buyWatch ? '#FBFBFF' : '#767687'}>
                      {dataAd.ccy}
                    </SpanStyled>
                  }
                  placeholder={typeText.placeholder}
                />
              </Form.Item>
              <Form.Item
                name={'paymentMethod'}
                style={{
                  height: '100%',
                  flex: 1.5,
                }}
                help={
                  !!error.payment && (
                    <span className={'helpInput'}>{error.payment}</span>
                  )
                }
                label={translate('dashboard.collapseBody.paymentMethod')}
              >
                <InputStyled
                  placeholder={translate('dashboard.collapseBody.chooseMethod')}
                  status={!!error.payment ? 'error' : ''}
                  suffix={
                    <div style={{transform: 'rotate(90deg)'}}>
                      <CollapseArrow/>
                    </div>
                  }
                  onClick={() => setMethodIsOpen(true)}
                />
              </Form.Item>
            </DrawerInput>
          </Form>
          <FCol>
            <AdContentPoint
              content1={translate('dashboard.collapseBody.price')}
              content2={item.price + ' ' + item.ccy}
              divider
            />
            <AdContentPoint
              content1={translate('announcements.totalSum')}
              content2={item.total_sum + ' ' + item.ccy}
              divider
            />
            <AdContentPoint
              content1={translate('announcements.limits')}
              content2={
                item.min_sum_limit +
                ' - ' +
                item.max_sum_limit +
                ' ' +
                item.fiat
              }
              divider
            />
            <AdContentPoint
              content1={translate('announcements.available')}
              content2={item.sum_left + ' ' + item.ccy}
              divider
            />
            <PaymentsTitle>
              <Typography.Text>{translate('payments.title')}</Typography.Text>
            </PaymentsTitle>
            {paymentMethodsList && paymentMethodsList.length > 0 && (
              <div style={{width: '100%', overflow: 'hidden'}}>
                <PaymentsRow id={`row-${item.id}`}>
                  {paymentMethodsList.map((item, index) => (
                    <>
                      <TagStyled
                        className={`slidable-tag`}
                        color={'#474755'}
                        key={`dashboard-collapse-tab-${index}`}
                      >
                        {item.label}
                      </TagStyled>
                    </>
                  ))}
                </PaymentsRow>
                <DividerStyled $margin={'15px 0 0 0'}/>
              </div>
            )}
            {item.transaction_time && (
              <AdContentPoint
                content1={translate('announcements.params.paymentTime')}
                content2={item.transaction_time}
                divider
              />
            )}
            <FCol className="gap-7">
              <FRow style={{paddingTop: '13px', paddingBottom: '5px'}}>
                <ProfileCircle/>
                <Typography.Text className="white">
                  {dataAd.owner_info.nickname}
                </Typography.Text>
              </FRow>
              <AdContentPoint
                className={'white'}
                dot
                content2={dataAd.owner_info.total_orders_amount}
                content1={translate(
                  'announcements.ownerInfo.totalOrdersAmount'
                )}
              />
              <FRow>
                <Dot style={{opacity: '0'}}/>
                <Typography.Text style={{lineHeight: '17px'}}>
                  {translate('announcements.ownerInfo.successOrdersAmount')}{' '}
                  {dataAd.owner_info.success_orders_amount} /{' '}
                  {translate('announcements.ownerInfo.cancelledOrdersAmount')}{' '}
                  {dataAd.owner_info.canceled_orders_amount}
                </Typography.Text>
              </FRow>
              <AdContentPoint
                className={'white'}
                dot
                content2={
                  (dataAd.owner_info.total_orders_amount !== 0
                    ? (dataAd.owner_info.success_orders_amount /
                      dataAd.owner_info.total_orders_amount) *
                    100
                    : 0) + '%'
                }
                content1={translate(
                  'announcements.ownerInfo.successOrdersPercent'
                )}
              />
              <AdContentPoint
                className={'white'}
                content1={translate(
                  'announcements.ownerInfo.uniqueAgentsAmount'
                )}
                content2={dataAd.owner_info.unique_agents_amount}
                dot
              />
            </FCol>
            <DividerStyled $margin={'17px 0'}/>
            {!!item?.deal_terms && (
              <TermsWrapper>
                <Typography.Text>
                  {translate('dashboard.collapseBody.terms')}
                </Typography.Text>
                <Typography.Text>{dataAd.deal_terms}</Typography.Text>
              </TermsWrapper>
            )}
          </FCol>
        </ConfigProvider>

        <ButtonWrapper>
          <Button
            width={'100%'}
            height={'40px'}
            style={{marginRight: '8px', maxWidth: '360px'}}
            onClick={isUpdate && isOpen ? handleUpdate : handleCreate}
            htmlType={'submit'}
          >
            {isUpdate
              ? translate('dashboard.collapseBody.update')
              : translate(`buttons.${actionType}`) + ' ' + item.ccy}
          </Button>
        </ButtonWrapper>
      </div>
      <MethodDrawer
        style={{
          width: methodIsOpen ? '100vw' : '0',
          padding: methodIsOpen ? '15px' : '0',
          overflow: methodIsOpen ? 'scroll' : 'hidden',
          left: 'auto',
        }}
      >
        <TitleWrapper style={{height: 'fit-content'}}>
          <div>
            <div onClick={() => setMethodIsOpen(false)}>
              <ArrowThin color="white"/>
            </div>
            <Typography.Title style={{opacity: methodIsOpen ? '1' : '0'}}>
              {translate('dashboard.collapseBody.paymentMethod')}
            </Typography.Title>
          </div>
          <IconWrapper onClick={() => setMethodIsOpen(false)}>
            <CloseIcon/>
          </IconWrapper>
        </TitleWrapper>
        <FCol style={{marginTop: '15px'}}>
          {paymentMethodsList?.length && methodArray(true, true)?.map((item, i) => (
            <React.Fragment key={i}>
              <FRow>
                <PaymentInList>
                  {item.label}
                </PaymentInList>
              </FRow>
              <DividerStyled/>
            </React.Fragment>
          ))}
        </FCol>
      </MethodDrawer>
    </DrawerStyled>
  );
};

const AdContentPoint = ({
                          content1,
                          content2,
                          divider,
                          dot,
                          className,
                        }: {
  content1: string | number;
  content2: string | number;
  divider?: boolean;
  dot?: boolean;
  className?: string;
}) => {
  return (
    <>
      <FRow>
        {dot && <Dot/>}
        <Typography.Text className={className}>{content1}</Typography.Text>
        <Typography.Text className={className}>{content2}</Typography.Text>
      </FRow>
      {
        divider && (<DividerStyled $margin={'0'}/>)
      }
    </>
  );
};


const PaymentInList = styled.div`
  display: flex;
  flex-direction: row;
  height: 65px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px 8px 16px;

  & svg {
    height: 32px;
    width: 32px;
  }

  color: #fbfbff;
  font-size: 14px;
  line-height: 17px;
`;
const PaymentsRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-top: 5px;
  gap: 5px;
  overflow: scroll;
  --webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  & span {
    text-overflow: unset !important;
    display: flex;
    flex-shrink: 0;
    padding: 12px;
    line-height: 15px;
  }
`;
const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: linear-gradient(to right, #6320e5, #7b59fd);
  border-radius: 50%;
`;
const FCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.gap-7 {
    gap: 7px;
  }
`;
const FRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  & span {
    color: #c3c3ce;
    font-size: 14px;
    line-height: 40px;
  }

  & span.white {
    color: #fbfbff;
    line-height: 17px;
  }
`;
const PaymentsTitle = styled.div`
  display: flex;
  padding-top: 8px;

  & span {
    color: #c3c3ce;
    font-size: 14px;
  }
`;
const Drawer = styled.div`
  display: flex;
  position: fixed !important;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  background: #14141f;
  transition: all 0.4s ease-in-out;
  align-items: center;
  flex-direction: column;

  & form {
    width: 100%;
  }

  & > div {
    width: 100%;
    height: 100%;
    position: relative;
  }

  @media (min-width: 768px) {
    display: none !important;
  }
`;

const DrawerStyled = styled(Drawer)<{
  $height?: string,
  $padding?: string,
  $overflow?: string,
}>`
  height: ${(props) => props.$height};
  padding: ${(props) => props.$padding};
  overflow: ${(props) => props.$overflow};
`

const MethodDrawer = styled(Drawer)`
  z-index: 2;
  height: 100vh;
  left: unset;
  right: 0;
`;
const DrawerInput = styled.div`
  display: flex;
  flex-direction: column;
`;
const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 7px;
  width: 100%;
  justify-content: space-between;

  & h1 {
    font-size: 24px;
    transition: all 0.2s ease;
  }

  & div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    gap: 15px;
  }

  & div h1 {
    margin: 0;
  }

  & div svg {
    transform: rotate(90deg);
    width: 24px;
    height: 24px;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;

  & svg {
    margin-top: 5px;
    width: 15px !important;
    height: 15px !important;
  }
`;

const DividerStyled = styled(Divider)<{
  $margin?: string,
}>`
  margin: ${(props) => props.$margin};
  background-color: #474755;
  width: 100%;
  left: 0;
`;


const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 100%;
`;

const TermsWrapper = styled.div`
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const InputStyled = styled(Input)`
  margin-top: 0;
`;

const SpanStyled = styled.span<{
  $color?: string,
}>`
  color: ${(props) => props.$color};
`;

const TagStyled = styled(Tag)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
`;
