import {Button, Form, Typography, useSimpleList} from '@pankod/refine-antd';
import {Suspense, useEffect, useMemo, useState} from 'react';
import {HttpError, useApiUrl, useCustom, useCustomMutation, useList,} from '@pankod/refine-core';
import {IP2PUser} from 'interfaces';
import {ICryptoCurrencyConstraintItem} from 'widgets/order/types';
import {ReactComponent as FileIcon} from '../../../shared/components/ui/assets/svg/fileIcon.svg';
import {
    AdPriceType,
    AdTypesEnum,
    CreateP2PAdSteps,
    ICoinAccountWallet,
    IConfigCurrencyPair,
    IFiat,
    IPaymentType,
    IRegion,
    P2PAdStatus,
} from 'features/p2p/createOrder/types';
import Modal from 'shared/components/ui/modal';
import {TFunction} from 'i18next';
import {notification} from 'antd';
import {useNavigate, useParams} from '@pankod/refine-react-router-v6';
import {FullScreenLoader} from '../../../shared/components/full-screen-loader';
import {useTranslation} from 'react-i18next';
import {IPaymentsType, IPaymentsTypeBuyModified} from '../dashboard/interface';
import {validateP2PTotalSumNumber} from '../../../shared/helpers/valideteP2PTotalSumNumber';
import ChooseAdType from 'features/p2p/createOrder/stepsContent/chooseAdType/chooseAdType';
import PageStepper from 'features/p2p/createOrder/pageStepper';
import PageHeading from 'features/p2p/createOrder/editPageHeading';
import CheckAdData from 'features/p2p/createOrder/stepsContent/checkAdData/checkAdData';
import ChooseLimitsAndPaymentType
    from 'features/p2p/createOrder/stepsContent/chooseLimitsAndPaymentType/chooseLimitsAndPaymentType';
import SetEditAdditionalParams from 'features/p2p/createOrder/stepsContent/setEditAdditionalParams';
import BN from 'bignumber.js';
import { CRYPTO_DP, CRYPTO_ROUND, ERROR_TYPES, FIAT_DP, FIAT_ROUND } from '../lib/constants';
import { validateLimitsAndPaymentType } from '../lib/validators';

export interface ICreateP2PAdInfo {
    owner_id?: number;
    ccy: string;
    fiat: string;
    price: number;
    price_type: AdPriceType;
    price_spread?: string;
    min_sum_limit: number;
    max_sum_limit: number;
    total_sum: number;
    reaction_time?: string;
    transaction_time?: string;
    auto_response?: string;
    deal_terms?: string;
    minimal_registration_days?: string;
    status?: P2PAdStatus;
    payment_info?: IPaymentType[];
    buy_payment_info?: IPaymentType[];
    region_id?: number;
    deleted_at?: string;
    under_maratorium?: boolean;
    type: AdTypesEnum;
}

export interface IPaymentLimitsErrors {
    region: string | null;
    payment: string | null;
}


const getPageSteps = (t: TFunction<string>) => [
    {
        stepUnderText: t('createAd.pageSteps.adTypeAndPrice'),
        width: 117,
        marginLeft: 0,
    },
    {
        stepUnderText: t('createAd.pageSteps.totalAmountAndPaymentMethod'),
        width: 120,
        marginLeft: -47,
    },
    {
        stepUnderText: t('createAd.pageSteps.additionalParams'),
        width: 123,
        marginLeft: -40,
    },
    {
        stepUnderText: t('createAd.pageSteps.dataVerification'),
        width: 50,
        marginLeft: -20,
    },
];
const REFETCH_CURRENCY_PAIR_LIST_INTERVAL = 60000;

const Index = () => {
    const {t} = useTranslation();
    const navigation = useNavigate();
    const apiUrl = useApiUrl();
    const [userData, setUserData] = useState<IP2PUser>();
    const params = useParams();


    const {listProps: currentAdInfo} = useSimpleList<ICreateP2PAdInfo>({
        resource: 'offer',
        dataProviderName: 'p2p',
        initialFilter: [{
            field: 'id',
            operator: 'eq',
            value: `${params.id}`
        }],
        queryOptions: {
            onSuccess: ({data}) => {
                setCurrentAd(data[0])
                setDefaults(data[0])
                data[0].deal_terms && setDeal(data[0].deal_terms)
                data[0].auto_response && setResponse(data[0].auto_response)
            }
        }
    })
    const setDefaults = (data: ICreateP2PAdInfo) => {
        setCreateAdType(data.type)
        setAdCurrencyPrice(+data.price)
        setMinLimit(`${data.min_sum_limit}`)
        setMaxLimit(`${data.max_sum_limit}`)
        setTotalSum(`${data.total_sum}`)
    }

    const {data: regionList, isLoading: isLoadingRegions} = useList<IRegion, HttpError>({
        resource: 'regions',
        dataProviderName: 'p2p',
        config: {
            pagination: {
                current: 1,
                pageSize: 30,
            },
        },
    });

    const [currentAd, setCurrentAd] = useState<ICreateP2PAdInfo>();
    const [createAdType, setCreateAdType] = useState(AdTypesEnum.BUY);
    const [currentCreateAdStep, setCurrentCreateAdStep] = useState(
        CreateP2PAdSteps.CHOOSE_AD_TYPE
    );
    const {data: paymentTypesBuyData} = useList<IPaymentsType>({
        dataProviderName: 'p2p',
        resource: 'payment-types',
        queryOptions: {
            retry: false,
        },
    });
    const paymentTypesBuyDataModified = paymentTypesBuyData?.data.map(
        (payment) => ({
            id: payment.id,
            uniq_id: `${payment.payment_type}-${payment.id}`,
            payment_type: payment.payment_type,
            icon: payment.icon,
            items: payment.items?.map((item) => ({
                id: item.id,
                uniq_id: `${payment.payment_type}-${item.id}`,
                payment_type: payment.payment_type,
                name: item.name,
                icon: item.icon,
            })),
        })
    );
    const paymentTypesBuyDataSorted = paymentTypesBuyDataModified?.sort(function (
        a,
        b
    ) {
        return a.id.toString().localeCompare(b.id.toString());
    });
    const [chosenFiat, setChosenFiat] = useState<IFiat>();
    const [chosenCurrency, setChosenCurrency] =
        useState<ICryptoCurrencyConstraintItem>();

    const [isOpenAddPaymentTypeSellModal, setIsOpenAddPaymentTypeSellModal] =
        useState(false);
    const [isOpenAddPaymentTypeBuyModal, setIsOpenAddPaymentTypeBuyModal] =
        useState(false);
    const [isNextBtnClick, setIsNextBtnClick] = useState(false);

    const [form] = Form.useForm();

    const formTotalSum = Form.useWatch('total-sum', form);
    const [chosenRegion, setChosenRegion] = useState<IRegion | undefined>(
        undefined
    );

    const [activePrice, setActivePrice] = useState(50);
    const [adCurrencyPrice, setAdCurrencyPrice] = useState(0);
    const [coinWalletBalance, setCoinWalletBalance] = useState(0);

    const [errors, setErrors] = useState<IPaymentLimitsErrors>(
        {} as IPaymentLimitsErrors
    );

    useList<IP2PUser, HttpError>({
        resource: 'account',
        dataProviderName: 'account',
        config: {
            pagination: {
                current: 1,
                pageSize: 1,
            },
        },
        queryOptions: {
            onSuccess: ({data}) => {
                if (!!data[0]) {
                    setUserData(data[0]);
                }
            },
        },
    });

    useCustom<{ data: ICoinAccountWallet[] }>({
        url: `${apiUrl}/${chosenCurrency?.currency?.toLowerCase()}-coin/api/account`,
        method: 'get',
        queryOptions: {
            enabled: chosenCurrency !== undefined,
            onSuccess: ({data}) => {
                setCoinWalletBalance(
                    !!data?.data?.length
                        ? Math.max(...data?.data?.map((coinWallet) => coinWallet?.amount))
                        : 0
                );
            },
            onError: (response) => {
                console.error('WALLET NOT FOUND');
                setCoinWalletBalance(0);
            },
            retry: false,
        },
    });

    const [userPaymentList, setUserPaymentList] = useState<Array<IPaymentType>>(
        []
    );
    const [userPaymentListBuy, setUserPaymentListBuy] = useState<IPaymentsTypeBuyModified[]>([]);
    const [pageUserPayments, setPageUserPayments] = useState(1);
    const [userPaymentListData, setUserPaymentListData] = useState<
        IPaymentType[]
    >([]);
    const {data: userPaymentListAPI} = useList<IPaymentType>({
        resource: 'payment',
        dataProviderName: 'p2p',
        config: {
            pagination: {
                current: pageUserPayments,
                pageSize: 25,
            },
            filters: [
                {
                    field: 'account_id',
                    operator: 'eq',
                    value: userData?.uid,
                },
                {
                    field: 'payment_type',
                    operator: 'ne',
                    value: 'cashPayment',
                },
            ],
        },
        queryOptions: {
            enabled: userData !== undefined,
            onSuccess: (res) => {
                if (pageUserPayments === 1) {
                    setUserPaymentListData([...res.data]);
                } else setUserPaymentListData([...userPaymentListData, ...res.data]);
            },
        },
    });

    const {data: fiatList, isLoading: isFiatListLoading} = useList<IFiat>({
        resource: 'fiat',
        dataProviderName: 'config',
        config: {
            pagination: {
                current: 1,
                pageSize: 15,
            },
        },
    });

    const {
        data: currencyConstraintDataList,
        isLoading: isLoadingCurrencyConstraintList,
    } = useList<ICryptoCurrencyConstraintItem, HttpError>({
        resource: 'currency-constraint',
        dataProviderName: 'config',
        config: {
            pagination: {
                current: 1,
                pageSize: 15,
            },
            filters: [
                {
                    field: 'is_available_p2p',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
    });

    const [issetDefaultPrice, setIssetDefaultPrice] = useState(false);
    const setDefaultCurrencyPrice = () => {
        if ((latestPriceRange.min !== '-1') && latestPrice !== '') {
            if (adCurrencyPrice < +latestPriceRange.min || adCurrencyPrice > +latestPriceRange.max) {
                setAdCurrencyPrice(+latestPrice);
                setIssetDefaultPrice(true);
            }
        }
    }
    const {data: currencyPairsList} = useList<IConfigCurrencyPair, HttpError>({
        resource: 'currency-pair',
        dataProviderName: 'config',
        config: {
            pagination: {
                current: 1,
                pageSize: 30,
            },
            filters: [
                {
                    field: 'is_available_p2p',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
        queryOptions: {
            refetchInterval: REFETCH_CURRENCY_PAIR_LIST_INTERVAL,
            onSuccess: ({data}) => {
                setLatestPrice(data.find((item) => item.pair === params.currencyPair)?.latest_price ?? '')
            }
        },
    });
    const [latestPrice, setLatestPrice] = useState('');
    const [latestPriceRange, setLatestPriceRange] = useState<{
        min: string | number,
        max: string | number
    }>({
        min: '-1',
        max: '0'
    })
    useEffect(() => {
        if (latestPrice !== '') {
            setLatestPriceRange({
                min: +latestPrice * (80 / 100),
                max: +latestPrice * (130 / 100)
            })
        }
    }, [latestPrice])
    useEffect(() => {
        !issetDefaultPrice && setDefaultCurrencyPrice()
    }, [latestPriceRange.min])

    function translateBackErrors(message: string) {
        if (message.includes('Unable to publish Ad, you already have one')) {
            return t('messages.error.maxAdLimit');
        }

        if (message.includes('The max_sum_limit must not exceed the total sum')) {
            return t('messages.error.maxLimitExceed');
        }
        return message;
    }

    const {mutate: customPut, isLoading: isLoadingEdit} = useCustomMutation();
    const [isOpenConfirmPriceModal, setIsOpenConfirmPriceModal] = useState(false);

    const onSubmitOrder = (status: P2PAdStatus, newPrice?: number) => {
        userData &&
        userData.id &&
        activePrice !== 0 &&
        customPut({
                url: `p2p/api/offer/${params.id}`,
                method: 'put',
                values: {
                    status: P2PAdStatus.TEMPLATE
                }
            },
            {
                onSuccess: () => customPut(
                    {
                        url: `p2p/api/offer/${params.id}`,
                        method: 'put',
                        values: {
                            status,
                            price: newPrice ?? adCurrencyPrice,
                            price_type: AdPriceType.FIXED,
                            min_sum_limit: +form.getFieldValue('min-limit'),
                            max_sum_limit: +form.getFieldValue('max-limit'),
                            total_sum: +form.getFieldValue('total-sum'),
                            payment_info:
                                createAdType === AdTypesEnum.SELL
                                    ? userPaymentList.map((item) => item.id)
                                    : undefined,
                            buy_payment_info:
                                createAdType === AdTypesEnum.BUY ? parseDataPayment() : undefined,
                            region_id: chosenRegion?.id,
                            deal_terms:
                                form.getFieldValue('deal-terms') === ''
                                    ? undefined
                                    : form.getFieldValue('deal-terms'),
                            auto_response:
                                form.getFieldValue('auto-response') === ''
                                    ? undefined
                                    : form.getFieldValue('auto-response'),
                        },
                    },
                    {
                        onSuccess: () => {
                            notification.success({
                                message: t('messages.success.adEditedSuccessfully'),
                            });
                            navigation('/p2p/my-announcements');
                        },
                        onError: (error) =>
                            notification.error({
                                message: t('messages.error.adCreateError'),
                                description: translateBackErrors(error.message),
                            }),
                    }
                )
            })

    };

    const parseDataPayment = () => {
        return userPaymentListBuy.map((payment) =>
            payment.payment_type === 'bankCardPayment' ||
            payment.payment_type === 'bankTransferPayment'
                ? {
                    bank_id: payment.id,
                    payment_type: payment.payment_type,
                    bank_name: payment.name,
                    icon: payment.icon,
                }
                : payment.payment_type === 'webServicePayment'
                    ? {
                        id: payment.id,
                        payment_type: payment.payment_type,
                        web_service_name: payment.name,
                        icon: payment.icon,
                    }
                    : {
                        id: payment.id,
                        payment_type: payment.payment_type,
                        name: payment.name,
                        icon: payment.icon,
                    }
        );
    };

    const iconsData = useList<IPaymentsType, HttpError>({
        dataProviderName: `p2p`,
        resource: `payment-types`,
    });

    const [totalSum, setTotalSum] = useState('');
    const [minLimit, setMinLimit] = useState('');
    const [maxLimit, setMaxLimit] = useState('');
    const [deal, setDeal] = useState('');
    const [response, setResponse] = useState('');

    const handleChangeType = (type: AdTypesEnum) => {
        setCreateAdType(type);
    };
    const handleChangeFiat = (fiat: IFiat) => {
    };
    const handleChangeCurrency = (fiat: ICryptoCurrencyConstraintItem) => {
    };
    const handleChangeCurrencyPrice = (value: number) => {
        setAdCurrencyPrice(value);
    };
    const handleChangeTotalSum = (value: string) => {
        setTotalSum(value === '' ? '0' : validateP2PTotalSumNumber(value));
    };
    const handleChangeMinLimit = (value: string) => {
        setMinLimit(value === '' ? '0' : value);
    };
    const handleChangeMaxLimit = (value: string) => {
        setMaxLimit(value === '' ? '0' : value);
    };
    const handleChangeRegion = (region: IRegion) => {
        setChosenRegion(region);
    };

    const handleChangeListBuy = (value: any[]) => {
        setUserPaymentListBuy(value);
    };
    const handleChangeList = (value: IPaymentType[]) => {
        setUserPaymentList(value);
    };

    const handleChangeDeal = (value: string) => {
        setDeal(value);
    };
    const handleChangeResponse = (value: string) => {
        setResponse(value);
    };

    const getFormValue = (title: 'total-sum' | 'min-limit' | 'max-limit') => {
        return form.getFieldValue(title) ? form.getFieldValue(title) : 0;
    }
    const getCreateStepComponent = () => {
        switch (currentCreateAdStep) {
            case CreateP2PAdSteps.CHOOSE_AD_TYPE:
                return (
                    <ChooseAdType
                        editAd
                        adCurrencyPrice={adCurrencyPrice}
                        handleChangeAdCurrencyPrice={handleChangeCurrencyPrice}
                        activePrice={activePrice}
                        isCurrencyListLoading={isLoadingCurrencyConstraintList}
                        isFiatListLoading={isFiatListLoading}
                        currencyList={currencyConstraintDataList?.data}
                        fiatList={fiatList?.data}
                        adType={createAdType}
                        chosenFiat={chosenFiat}
                        chosenCurrency={chosenCurrency}
                        handleChangeAdType={handleChangeType}
                        handleChangeCurrency={handleChangeCurrency}
                        handleChangeFiat={handleChangeFiat}
                        isNextBtnClick={isNextBtnClick}
                    />
                );
            case CreateP2PAdSteps.CHOOSE_LIMITS_AND_PAYMENT_TYPE:
                return (
                    <ChooseLimitsAndPaymentType
                        totalSum={totalSum}
                        handleChangeTotalSum={handleChangeTotalSum}
                        minLimit={minLimit}
                        maxLimit={maxLimit}
                        handleChangeMinLimitMain={handleChangeMinLimit}
                        handleChangeMaxLimitMain={handleChangeMaxLimit}
                        errors={errors}
                        setErrors={setErrors}
                        setUserPaymentListBuy={handleChangeListBuy}
                        createAdType={createAdType}
                        handleOpenChoosePaymentTypeBuyModal={() =>
                            setIsOpenAddPaymentTypeBuyModal(true)
                        }
                        handleOpenChoosePaymentTypeSellModal={() =>
                            setIsOpenAddPaymentTypeSellModal(true)
                        }
                        form={form}
                        adCurrencyPrice={adCurrencyPrice}
                        activePrice={activePrice}
                        currencyBalance={coinWalletBalance}
                        chosenCurrency={chosenCurrency}
                        chosenFiat={chosenFiat}
                        regionList={regionList ? regionList.data : []}
                        chosenRegion={chosenRegion}
                        onSelectRegion={handleChangeRegion}
                        userPaymentList={userPaymentList}
                        userPaymentListBuy={userPaymentListBuy}
                        deletePaymentTypeBuy={(deletePaymentTypeBuyId) => 
                            setUserPaymentListBuy((list) =>
                                list.filter((item) => item.uniq_id !== deletePaymentTypeBuyId)
                            )
                        }
                        deletePaymentType={(deletePaymentTypeId) =>
                            setUserPaymentList((list) =>
                                list.filter((item) => item.id !== deletePaymentTypeId)
                            )
                        }
                        iconsData={iconsData.data?.data}
                    />
                );
            case CreateP2PAdSteps.SET_ADDITIONAL_PARAMS:
                return (
                    <SetEditAdditionalParams
                        form={form}
                        deal={deal}
                        handleChangeDeal={handleChangeDeal}
                        handleChangeResponse={handleChangeResponse}
                        response={response}
                    />
                );
            case CreateP2PAdSteps.CHECK_AD_DATA:
                return (
                    <CheckAdData
                        additionalParamsForm={form}
                        adPriceType={AdPriceType.FIXED}
                        adCurrencyPrice={adCurrencyPrice}
                        chosenCurrency={chosenCurrency}
                        chosenFiat={chosenFiat}
                        chosenRegion={chosenRegion}
                        totalSum={getFormValue('total-sum')}
                        minLimit={getFormValue('min-limit')}
                        maxLimit={getFormValue('max-limit')}
                        createAdType={createAdType}
                        userPaymentList={userPaymentList}
                        userPaymentListBuy={userPaymentListBuy}
                        iconsData={iconsData.data?.data}
                        record={currentAd}
                    />
                );
        }
    };

    const isNextStepButtonDisabled = () => {
        switch (currentCreateAdStep) {
            case CreateP2PAdSteps.CHOOSE_AD_TYPE:
                return !(
                    (
                        userData &&
                        userData.id &&
                        chosenCurrency &&
                        chosenFiat &&
                        activePrice !== 0
                    )
                );
            case CreateP2PAdSteps.CHOOSE_LIMITS_AND_PAYMENT_TYPE:
                const formMaxLimitErrors = form.getFieldError('max-limit')

                return formMaxLimitErrors.length || !validateLimitsAndPaymentType({
                    activePrice,
                    adType: createAdType,
                    maxLimit: form.getFieldValue('max-limit'),
                    minLimit: form.getFieldValue('min-limit'),
                    cryptoSum: form.getFieldValue('total-sum'),
                    walletBalance: coinWalletBalance,
                    chosenFiat,
                    chosenRegion,
                    paymentMethodsList: userPaymentList,
                    buyPaymentMethodsList: userPaymentListBuy
                })
            case CreateP2PAdSteps.CHECK_AD_DATA:
                return isLoadingEdit;
            default:
                return false;
        }
    };

    const limitsAndPaymentValidation = () => {
        if (
            currentCreateAdStep === CreateP2PAdSteps.CHOOSE_LIMITS_AND_PAYMENT_TYPE
        ) {
            if (!chosenRegion) {
                setErrors((prevState) => ({
                    ...prevState,
                    region: `${t('p2p.chooseLimitsAndPaymentsType.inputs.chooseRegion')}`,
                }));
            }
            if (
                (createAdType === AdTypesEnum.BUY && userPaymentListBuy.length === 0) ||
                (createAdType === AdTypesEnum.SELL && userPaymentList.length === 0)
            ) {
                setErrors((prevState) => ({
                    ...prevState,
                    payment: `${t(
                        'p2p.chooseLimitsAndPaymentsType.inputs.choosePayment'
                    )}`,
                }));
            }
        }
    };

    const handleNext = () => {
        if (currentCreateAdStep === CreateP2PAdSteps.CHOOSE_LIMITS_AND_PAYMENT_TYPE)
            form.validateFields(['total-sum', 'min-limit', 'max-limit']).then();
        setIsNextBtnClick(true);
        limitsAndPaymentValidation();
        if (!chosenFiat) {
            notification.warning({
                message: t('messages.warning.fiatNotSelected'),
            });
        }
        if (!chosenCurrency) {
            notification.warning({
                message: t('messages.warning.activeNotSelected'),
            });
        }
        if (isNextStepButtonDisabled()) return;
        currentCreateAdStep === CreateP2PAdSteps.CHECK_AD_DATA
            ? (adCurrencyPrice < +latestPriceRange.min || adCurrencyPrice > +latestPriceRange.max)
                ? setIsOpenConfirmPriceModal(true)
                : onSubmitOrder(P2PAdStatus.CREATED)
            : setCurrentCreateAdStep((state) => state + 1);
    };

    // update price on pair change
    useEffect(() => {
        form.resetFields(['max-limit', 'min-limit', 'total-sum']);
        if (currencyPairsList) {
            const pairName = `${chosenCurrency?.currency}${chosenFiat?.currency}`;
            const pair = currencyPairsList.data.find((item) => item.pair === pairName);
            setActivePrice(pair?.latest_price ? BN(pair.latest_price).dp(FIAT_DP, FIAT_ROUND).toNumber() : 0);
        }
    }, [chosenCurrency, chosenFiat, currencyPairsList]);
    useEffect(() => {
        if (!isLoadingRegions && regionList && regionList.data[0]) {
            setChosenRegion(regionList.data.find(
                (reg: IRegion) => reg.id === currentAd?.region_id
            ))
        }
    }, [isLoadingRegions, currentAd])
    useEffect(() => {
        if (!isLoadingCurrencyConstraintList && currencyConstraintDataList) {
            setChosenCurrency(currencyConstraintDataList.data.find(
                (item: ICryptoCurrencyConstraintItem) => item.currency === currentAd?.ccy
            ))
        }
    }, [isLoadingCurrencyConstraintList, currentAd])
    useEffect(() => {
        if (!isFiatListLoading && fiatList) {
            setChosenFiat(fiatList.data.find(
                (item: IFiat) => item.currency === currentAd?.fiat
            ))
        }
    }, [isFiatListLoading, currentAd])
    useEffect(() => {
        let arr = currentAd ? (currentAd.payment_info ? currentAd.payment_info : currentAd.buy_payment_info ?? []) : [];
        const getPaymentDetails = (item: IPaymentType, i: number) => {
            if (currentAd?.type === 'sell') {
                return {...item, payment_details: item.payment_details}
            } else {
                return {...item, payment_details: [arr[i]]}
            }
        }
        let result: any[] = [];
        currentAd?.buy_payment_info &&
        currentAd?.buy_payment_info.map((paymentItem, i) => {
            result.push(getPaymentDetails(paymentItem, i))
        })
        currentAd?.payment_info &&
        currentAd?.payment_info.map((paymentItem, i) => {
            result.push(getPaymentDetails(paymentItem, i))
        })
        currentAd?.buy_payment_info &&
        setUserPaymentListBuy(result.map((item) => (
            {
                id: item.bank_id ?? item.id,
                payment_type: item.payment_type,
                uniq_id: item.bank_id ?? `${item.payment_type}-${item.id}`,
                icon: item.icon,
                name: item.bank_name ?? item.web_service_name
            }
        )))
        currentAd?.payment_info &&
        setUserPaymentList(result)
    }, [currentAd])
    const disableDraft = useMemo(
        () =>
            !(
                currentCreateAdStep === CreateP2PAdSteps.CHECK_AD_DATA &&
                !isLoadingEdit &&
                userData &&
                userData.id &&
                chosenCurrency &&
                chosenFiat &&
                activePrice !== 0 &&
                chosenRegion &&
                +form.getFieldValue('max-limit') &&
                +form.getFieldValue('min-limit') &&
                +form.getFieldValue('max-limit') >= +form.getFieldValue('min-limit') &&
                +form.getFieldValue('total-sum') &&
                +form.getFieldValue('min-limit') >= +chosenFiat.dollar_exchange * 5 &&
                +form.getFieldValue('max-limit') <=
                +chosenFiat.dollar_exchange * 50000 &&
                +form.getFieldValue('total-sum') > 0 &&
                ((userPaymentList.length <= 5 &&
                        userPaymentList.length > 0 &&
                        createAdType === AdTypesEnum.SELL &&
                        coinWalletBalance >= +form.getFieldValue('total-sum')) ||
                    (userPaymentListBuy.length <= 5 &&
                        userPaymentListBuy.length > 0 &&
                        createAdType === AdTypesEnum.BUY))
            ),
        [
            activePrice,
            chosenCurrency,
            chosenFiat,
            chosenRegion,
            coinWalletBalance,
            createAdType,
            currentCreateAdStep,
            form,
            isLoadingEdit,
            userData,
            userPaymentList.length,
            userPaymentListBuy.length,
        ]
    );
    const [saveStatus, setSaveStatus] = useState<P2PAdStatus>(P2PAdStatus.CREATED)
    const handleOpenConfirmPriceModal = () => {
        setSaveStatus(P2PAdStatus.CREATED);
        setIsOpenConfirmPriceModal(true)
    }
    const handleButtonClick = (type: 'next' | 'draft') => {
        if (type === 'next') {
            if (currentCreateAdStep === CreateP2PAdSteps.CHECK_AD_DATA) {
                if (adCurrencyPrice < +latestPriceRange.min || adCurrencyPrice > +latestPriceRange.max)
                    return handleOpenConfirmPriceModal
                else return handleNext
            } else return handleNext
        } else {
            if (adCurrencyPrice < +latestPriceRange.min || adCurrencyPrice > +latestPriceRange.max) {
                setSaveStatus(P2PAdStatus.TEMPLATE);
                setIsOpenConfirmPriceModal(true)
            } else {
                onSubmitOrder(P2PAdStatus.TEMPLATE)
            }
        }
    }
    const getNextButtonText = () => {
        return currentCreateAdStep === CreateP2PAdSteps.CHECK_AD_DATA
            ? t('buttons.publish')
            : t('buttons.next');
    }
    const getDraftButtonClass = () => disableDraft ? 'disabled-draft' : 'active-draft';
    const handleAddChosenTypesBuy = (payments: IPaymentsTypeBuyModified[]) => {
        setUserPaymentListBuy(payments);
        setErrors((prevState) => ({
            ...prevState,
            payment: null,
            region: null,
        }));
        setErrors((prevState) => ({...prevState, payment: null}));
    }
    const handleAddChosenTypes = (payments: IPaymentType[]) => {
        setUserPaymentList(payments);
        setErrors((prevState) => ({
            ...prevState,
            payment: null,
            region: null,
        }));
        handleChangeList(payments);
        setErrors((prevState) => ({...prevState, payment: null}));
    }
    return (
        <Suspense fallback={<FullScreenLoader/>}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 24}}>
                <PageHeading onClose={() => navigation('/p2p/dashboard')}/>
                <PageStepper
                    labelPlacement="vertical"
                    initial={1}
                    steps={getPageSteps(t)}
                    current={currentCreateAdStep + 1}
                />
                <Form
                    validateTrigger={['onChange', 'onBlur']}
                    name="choose-limits-form"
                    form={form}
                    layout="vertical"
                >
                    {getCreateStepComponent()}
                </Form>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', gap: 16}}>
                        <Button
                            style={{minWidth: 180}}
                            type="primary"
                            onClick={handleButtonClick('next')}
                        >
                            {getNextButtonText()}
                        </Button>
                        <Button
                            disabled={currentCreateAdStep === CreateP2PAdSteps.CHOOSE_AD_TYPE}
                            style={{minWidth: 180}}
                            type="dashed"
                            onClick={() => setCurrentCreateAdStep((state) => state - 1)}
                        >
                            {t('buttons.back')}
                        </Button>
                    </div>
                    <Button
                        style={{color: '#FBFBFF'}}
                        disabled={disableDraft}
                        type="link"
                        onClick={() => handleButtonClick('draft')}
                    >
                        <div
                            style={{display: 'flex', gap: 8, alignItems: 'center'}}
                            className={getDraftButtonClass()}
                        >
                            <FileIcon/>
                            <Typography.Text>{t('buttons.saveAsDraft')}</Typography.Text>
                        </div>
                    </Button>
                </div>
                <Modal.ConfirmSetDefaultAdPrice
                    marketPrice={+latestPrice}
                    onClose={() => {
                        setCurrentCreateAdStep(CreateP2PAdSteps.CHOOSE_AD_TYPE)
                        setIsOpenConfirmPriceModal(false)
                    }}
                    priceCcy={chosenCurrency?.currency ?? ''}
                    open={isOpenConfirmPriceModal}
                    onOk={onSubmitOrder}
                    status={saveStatus}
                />
                {/* Модалка добавления способа оплаты для покупки */}
                <Modal.AddPaymentTypeBuy
                    handleClickAddNew={() => navigation('/p2p/settings/payment')}
                    handleClickAddChosenPaymentTypesBuy={handleAddChosenTypesBuy}
                    userPaymentListBuy={userPaymentListBuy}
                    open={isOpenAddPaymentTypeBuyModal}
                    onCancel={() => setIsOpenAddPaymentTypeBuyModal(false)}
                    title={t('payments.addingAPaymentMethod')}
                    paymentTypesBuyData={paymentTypesBuyDataSorted ?? []}
                />

                {/* Модалка добавления способа оплаты для продажи */}
                <Modal.AddPaymentTypeSell
                    userPaymentList={userPaymentList}
                    open={isOpenAddPaymentTypeSellModal}
                    onCancel={() => setIsOpenAddPaymentTypeSellModal(false)}
                    title={t('createAd.choosingAPaymentMethod')}
                    userPaymentTypes={userPaymentListData}
                    handleClickAddChosenPaymentTypes={handleAddChosenTypes}
                    handleClickAddNew={() => navigation('/p2p/settings/payment')}
                    iconsData={iconsData.data?.data}
                    setPage={setPageUserPayments}
                    userPaymentListAPI={userPaymentListAPI}
                />
            </div>
        </Suspense>
    );
};


export default Index;
