import React, { useEffect } from 'react';
import Table, { ITable } from './index';

interface SmartITable extends ITable {
  lastId?: string;
  debug?: boolean;
  infinity?: boolean;
  loadingIndicator?: boolean;
  onFetch?: () => void;
}

const SmartTable: React.FC<SmartITable> = ({
  debug = false,
  infinity = false,
  dataSource = [],
  onFetch = () => null,
  scroll,
  lastId,
  ...props
}) => {
  useEffect(() => {
    const tableContent = document.querySelector('.ant-table-body');

    const Event = (event: any) => {
      let maxScroll = event.target.scrollHeight - event.target.clientHeight;
      let currentScroll = event.target.scrollTop;
      if (currentScroll === maxScroll) {
        onFetch();
      }
    };

    tableContent?.addEventListener('scroll', Event);

    return () => tableContent?.removeEventListener('scroll', Event);
  }, [dataSource]);

  if (!infinity) {
    return <Table dataSource={dataSource} {...props} />;
  }

  return (
    <Table
      onChange={(props) => console.log(props)}
      dataSource={dataSource}
      scroll={scroll}
      rowKey={'id'}
      pagination={false}
      {...props}
    />
  );
};

export default SmartTable;
