import React, {useEffect, useState} from 'react';
import {Typography} from 'antd';
import {ErrorBoundary} from '../../../app/ErrorBoundary';
import Breadcrumbs from 'shared/components/ui/breadcrumbs';
import {SpotMyDealsTable} from '../../../widgets/spot/myDeals/table';
import {ConfigProvider} from '@pankod/refine-antd';
import {IMyDealsRow, IResponseMyDeals, SpotRangeFilterValue,} from '../../../shared/interfaces/spot';
import {RangeValue} from '../../p2p/myDeals/cards/myTrades.p';
import {IFilterHistory, ISortHistory,} from '../../../widgets/spot/ordersTable';
import dayjs from 'dayjs';
import {createDateAsUTC} from '../../../shared/helpers/convertTime';
import {useTranslation} from 'react-i18next';
import {API_URL, DEFAULT_PAIR_WITH_DASH,} from '../../../packages/keycloak-client/constants';
import {useLocation, useNavigate} from '@pankod/refine-react-router-v6';
import {axios} from '../../../shared/exios';
import {useAuthState} from '../../../useAuthState';
import styled from 'styled-components';
import {useCookiesCustom} from '../../../shared/hooks/useCookieCustom';

const SpotDealsTableContainer = styled.div<{ $dealsLength: number }>`
    height: 100%;
    padding-bottom: ${(props) => (props.$dealsLength === 0 ? 22 : 0)}px;
`;

const MySpotDeals = () => {
    const navigate = useNavigate();
    const {cookie, removeCookie} = useCookiesCustom();
    const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid'];
    const {t} = useTranslation();
    const location = useLocation();
    const [myDealsData, setMyDealsData] = useState<IMyDealsRow[]>([]);

    const [sort, setSort] = useState<ISortHistory>({
        date: {
            field: 'created_at',
            order: 'desc',
        },
    });

    const [_, setCurrentPage] = useState<number>(1);
    const [filter, setFilter] = useState<IFilterHistory>({});
    const [range, setRange] = useState<SpotRangeFilterValue>('6month');
    const [rangeValue, setRangeValue] = useState<RangeValue>();
    const [activePair, setActivePair] = useState(
        () => localStorage['activePair']
    );

    const handleRangeDate = (value: RangeValue) => {
        if (value) {
            setRangeValue(value);
            setRange(null);
            setFilter({
                ...filter,
                created_at: {
                    field: 'created_at',
                    operator: '>=',
                    value: value[0],
                },
                created_at2: {
                    field: 'created_at',
                    operator: '<=',
                    value: value[1],
                },
            });
        }
    };

    async function getMyDeals() {
        try {
            const res = await axios.post(
                `${API_URL}/${activePair
                    ?.replace('-', '')
                    ?.toLowerCase()}-spot/api/orders/search`,
                {
                    filters: [
                        {
                            field: 'uid',
                            operator: '=',
                            value: uid,
                        },
                        ...Object.values(filter),
                    ],
                    sort: [...Object.values(sort)],
                });
            const locData = res?.data?.data as any as IResponseMyDeals[];
            setMyDealsData(
                locData.map((item, index): IMyDealsRow => {
                    return {
                        id: item.id + '',
                        created_at: createDateAsUTC(
                            new Date(item.created_at)
                        ).toLocaleString(undefined, {
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        }),
                        pair: activePair,
                        type: item.type as 'buy' | 'sell',
                        price: +item.price,
                        amount: item.sum,
                        commission: item.commission,
                        dealAmount: +item.price * +item.sum + '',
                        amountInFiat: +item.price * +item.sum + '',
                    };
                })
            );
        } catch (e) {
            console.log('getMyDeals error', e);
        }
    }

    useEffect(() => {
        setActivePair(() => localStorage['activePair']);
    }, []);

    useEffect(() => {
        getMyDeals().then();
    }, [filter, sort]);

    useEffect(() => {
        setActivePair(() => localStorage['activePair']);
    }, [location?.pathname]);

    useEffect(() => {
        if (!!range) {
            let dates: dayjs.Dayjs[] = [];
            setRangeValue([null, null]);
            switch (range) {
                case 'day':
                    dates = [dayjs(new Date()).subtract(1, 'day'), dayjs(new Date())];
                    break;
                case 'week':
                    dates = [dayjs(new Date()).subtract(1, 'week'), dayjs(new Date())];
                    break;
                case 'month':
                    dates = [dayjs(new Date()).subtract(1, 'month'), dayjs(new Date())];
                    break;
                case '3month':
                    dates = [dayjs(new Date()).subtract(3, 'month'), dayjs(new Date())];
                    break;
                case '6month':
                    dates = [dayjs(new Date()).subtract(6, 'month'), dayjs(new Date())];
                    break;
            }
            setFilter({
                ...filter,
                created_at: {
                    field: 'created_at',
                    operator: '>=',
                    value: dates[0],
                },
                created_at2: {
                    field: 'created_at',
                    operator: '<=',
                    value: dates[1],
                },
            });
        }
    }, [range]);

    useEffect(() => {
        setCurrentPage(1);
    }, [filter]);

    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}
        >
            <ConfigProvider
                theme={{
                    components: {
                        Typography: {
                            colorSuccess: '#58BF92',
                            colorError: '#F16063',
                        },
                        Tag: {
                            colorPrimary: '#FBFBFF',
                            colorPrimaryHover: 'inherit',
                            colorText: '#767687',
                        },
                        DatePicker: {
                            lineWidth: 0,
                        },
                    },
                }}
            >
                <div className={'flex-column gap-24'} style={{height: '100%'}}>
                    <Breadcrumbs
                        items={[
                            {
                                title: t('spot.myDeals.breadcrumbs.spotTrading'),
                                link: `/spot/dashboard/${
                                    activePair?.replace(new RegExp('-', 'g'), '-') ||
                                    DEFAULT_PAIR_WITH_DASH
                                }`,
                            },
                            {
                                title: `${t(
                                    'spot.myDeals.breadcrumbs.orderBook'
                                )} ${activePair.replace('-', '/')}`,
                                link: `/spot/dashboard/my-deals/${activePair?.replace(
                                    new RegExp('-', 'g'),
                                    '-'
                                )}`,
                            },
                        ]}
                    />
                    <Typography.Title>{t('spot.myDeals.title')}</Typography.Title>
                    <SpotDealsTableContainer $dealsLength={myDealsData.length}>
                        <SpotMyDealsTable
                            isAuth={!!cookie['token']}
                            myDeals={myDealsData}
                            range={range}
                            handleRangeDate={handleRangeDate}
                            handleNextPage={() => {
                            }}
                            rangeValue={rangeValue}
                            setRange={setRange}
                            setSort={setSort}
                        />
                    </SpotDealsTableContainer>
                </div>
            </ConfigProvider>
        </ErrorBoundary>
    );
};

export {MySpotDeals};
